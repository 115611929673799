@import "../variables";
@import "../mixins";
@import "../breakpoints";

.summary-payment-box {
    width: 100%;
    background: $color-white;
    border-radius: 14px;
    padding: 18px 21px;
    margin-top: 18px;
    &__options {
        display: flex;
        align-items: center;
        margin-top: 19px;
        @include respond-to('medium') {
            flex-wrap: wrap;
        }
    }
    &__option {
        display: flex;
        align-items: center;
        margin-right: 50px;
        @include respond-to('medium') {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
    &.error {
        span, label {
            color: red;
        }
    }
}