@import "../variables";
@import "../mixins";
@import "../breakpoints";

.welcome-page {
    height: 80vh;
    width: 100%;
    padding: 0 16px;
    margin: 0 auto;
    overflow: hidden;
    max-width: calc(1920px - 240px + 2 * 16px);
    @include respond(1500px) {
        height: 75vh;
    }
    @include respond(900px) {
        max-width: calc(1920px - 240px + 2 * 8px);
        padding: 0 8px;
        height: auto;
    }
    @include respond($phone) {
        background: #FFF;
    }
    &__section {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include respond-to('medium') {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            margin: 0 auto;
            padding: 0;
            width: 100%;
        }
        @include respond(900px) {
            margin-top: 60px !important;
        }
        &>[header] {
            @include respond-to('medium') {
                font-size: 24px;
            }
        }
        @include setMarginTopSection;
        .wppizza-dbp-form {
            display: none !important;
        }
        .desktop {
            @include respond($phone) {
                display: none;
            }
        }
        .mobile {
            display: none;
            @include respond($phone) {
                display: block;
                position: relative;
                margin-bottom: 15px;
            }
            img {
                width: 100%;
            }
            p {
                position: absolute;
                bottom: 10px;
                width: 100%;
                text-align: center;
                color: #FFF;
                font-size: 36px;
                letter-spacing: 5px;
                text-transform: uppercase;
            }
        }
    }
    &__header {
        padding: 0;
        margin-bottom: 7.1rem;
        @include respond-to('medium') {
            margin-bottom: 3rem;
            margin-left: 0;
        }
    }
    &__subheader {
        margin-bottom: 4.1rem;
    }
    &__footer {
        margin: 0 120px;
        @include respond-to('medium') {
            margin: 0 15px;
        }
    }
    &__form-container {
        width: 752px;
        @include respond-to('medium') {
            width: 100%;
            min-width: 0;
            margin-left: 0;
        }
        .js-form {
            display: flex;
            flex-wrap: wrap;
        }
        .numberInput {
            width: 18%;
            margin-left: auto;
        }
        .streetInput {
            width: 65%;
            @include respond($phone) {
                width: 100%;
            }
        }
        .cityInput {
            width: 35%;
            @include respond($phone) {
                width: 100%;
            }
        }
        .input__error {
            @include respond($phone) {
                position: relative;
            }
        }
        .buttonInput {
            margin-left: auto;
            margin-top: 50px;
            @include respond-to('medium') {
                width: 100%;
                margin-top: 25px;
            }
            @include respond($phone) {
                margin-top: 15px;
            }
        }
    }
    &__premises {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #FFF;
        border-top: 12px solid #c71d22;
        padding-top: 25px;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        z-index: 100;
        @include respond(1500px) {
            padding-top: 15px;
        }
        @include respond($phone) {
            border: 0;
        }
        &-intro {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            //width: 400px;
            text-align: center;
            margin-bottom: 15px;
            @include respond($phone) {
                width: auto;
            }
        }
        &-content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            .premises__content {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-top: 15px;
                @include respond(900px) {
                    flex-wrap: wrap;
                    margin-top: 5px;
                }
            }
            .premise {
                background-color: #F32200;
                margin: 20px 2.5% 70px 2.5%;
                padding: 15px;
                width: 18.5%;
                border-radius: 7px;
                position: relative;
                @include respond($phone) {
                    margin: 0 15px 80px 15px;
                    width: 250px;
                }
                h3, p, a {
                    color: $color-white;
                    display: block;
                    text-align: center;
                    font-weight: 300;
                    letter-spacing: 1px;
                    @include respond($phone) {
                        font-size: 12px;
                    }
                }
                h3 {
                    position: relative;
                    font-size: 24px;
                    text-transform: uppercase;
                    font-weight: bold;
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                    letter-spacing: 4px;
                    @include respond($phone) {
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 35%;
                        border-top: 1px solid $color-white;
                        width: 30%;
                        @include respond($phone) {
                            bottom: 7px;
                        }
                    }
                }
                p, a {
                    line-height: 24px;
                }
                .accentBox {
                    position: absolute;
                    background-color: #83220A;
                    bottom: -70px;
                    width: 90%;
                    left: 5%;
                    padding: 15px;
                    border-radius: 7px;
                    @include respond($phone) {
                        padding: 7px;
                        bottom: -50px;
                    }
                }
                .accent {
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 21px;
                    color: #FFF;
                    font-weight: bold;
                    display: block;
                    letter-spacing: 5px;
                    @include respond($phone) {
                        font-size: 14px;
                        line-height: 21px;
                    }
                    &:hover {
                        opacity: .7;
                    }
                }
            }
            .description__header {
                background-color: #628E19;
                font-size: 29px;
                padding: 10px;
                color: #FFF;
                font-weight: 500;
                width: 500px;
                text-align: center;
                border-radius: 7px;
                margin: auto;
                @include respond(1500px) {
                    font-size: 36px;
                }
                @include respond($phone) {
                    width: 100%;
                    font-size: 18px;
                    margin: 0 15px;
                    line-height: 15px;
                }
            }
            .welcome__description {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                width: 100%;
                background-color: #EBEBEB;
                margin: 50px;
                border-radius: 13px;
                padding: 50px;
                @include respond(1500px) {
                    padding-bottom: 5px;
                    margin-top: 25px;
                }
                @include respond($phone) {
                    margin: 15px;
                    padding: 15px;
                }
                &-bg {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    filter: blur(20px);
                    -webkit-filter: blur(20px);
                    height: 100%;
                    width: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    &:after {
                        content: '';
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        top:0;
                        left:0;
                        background:rgba(0,0,0,0.4);
                        opacity: 1;
                        transition: all 0.5s;
                        -webkit-transition: all 0.5s;
                        -moz-transition: all 0.5s;
                    }
                }
                &-text {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    &.mobile {
                        display: none;
                        @include respond($phone) {
                            display: flex;
                        }
                    }
                    &.desktop {
                        @include respond($phone) {
                            display: none;
                        }
                    }
                }
                p {
                    color: #070707;
                    margin-bottom: 20px;
                    line-height: 36px;
                    font-size: 24px;
                    text-align: center;
                    z-index: 1;
                    font-weight: 300;
                    @include respond(1500px) {
                        margin-bottom: 15px;
                    }
                    @include respond(900px) {
                        padding: 0 30px;
                    }
                    @include respond($phone) {
                        font-size: 9px;
                        line-height: 16px;
                        padding: 0;
                    }
                    &:last-child {
                        width: 100%;
                    }
                }
                b {
                    font-weight: bold;
                }
            }
        }
        h2 {
            position: relative;
            font-size: 48px;
            color: #000;
            margin-bottom: 15px;
            padding-bottom: 15px;
            @include respond($phone) {
                text-align: center;
                padding-bottom: 0;
                font-size: 14px;
            }
            &:after {
                content: '';
                display: none;
                position: absolute;
                bottom: 0;
                left: 111px;
                width: 60px;
                border-bottom: 5px solid $color-grey-light;
            }
        }
        a {
            &:hover {
                opacity: .7;
                h3 {
                    opacity: .7;
                }
            }
        }
        h4 {
            font-size: 18px;
            font-weight: normal;
            color: $color-grey-light;
        }
        .arrow__content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 20px;
            @include respond($phone) {
                margin-top: 0;
            }
            &.second {
                //margin: 75px;
                margin: 45px;
                @include respond($phone) {
                    margin: 15px;
                }
                .arrowDown {
                    border-left: 45px solid transparent;
                    border-right: 45px solid transparent;
                    border-top: 30px solid #EBEBEB;
                    @include respond($phone) {
                        border-left: 15px solid transparent;
                        border-right: 15px solid transparent;
                        border-top: 13px solid #EBEBEB;
                    }
                }
            }
        }
        .arrowDown {
            width: 0;
            height: 0;
            border-left: 45px solid transparent;
            border-right: 45px solid transparent;
            border-top: 30px solid #c71d22;
            @include respond($phone) {
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-top: 13px solid #c71d22;
            }
        }
    }
    input {
        border-radius: 7px;
        font-size: 21px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 0.25px solid #e0e0e0;
        height: 62px;
        padding: 2rem 3rem;
        @media (max-width: 1440px) {
            font-size: 16px;
            height: 50px;
        }
        @include respond($phone) {
            font-size: 14px;
            padding: 1rem 2rem;
            height: 35px;
        }
        @include respond($phone) {}
        &::placeholder {
            color: #585858;
            font-size: 21px;
            text-transform: uppercase;
            @media (max-width: 1440px) {
                font-size: 16px;
            }
            @include respond($phone) {
                font-size: 14px;
            }
        }
    }
    .dropdownSelect__filter {
        font-size: 21px;
        color: #585858;
        @media (max-width: 1440px) {
            font-size: 16px;
            padding: 15px;
        }
        @include respond($phone) {
            font-size: 14px;
            padding: 8px;
        }
    }
    //#placesNumberMap {
    //    width: 270px;
    //    @media (max-width: 1440px) {
    //        width: 200px;
    //    }
    //}
}
.lokal-modal {
    .modal__content {
        min-height: 225px;
        .item {
            width: 100%;
            text-align: center;
            line-height: 25px;
            &:first-child {
                margin-bottom: 15px;
            }
        }
        span {
            font-weight: bold;
        }
        h1 {
            text-align: center;
            margin-bottom: 15px;
        }
        button {
            margin: 0 auto;
        }
    }
}
.payment-modal {
    .modal__content {
        min-height: 225px;
    }
}