@import "../../themes/variables";
@import "../../themes/mixins";
@import "../../themes/breakpoints";

.popup {
  display: none;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.32);
  &.is-active {
    display: flex;
  }
  &__content {
    @include absPostionCenter;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $color-white;
    max-width: 782px;
    width: 80%;
    min-height: 314px;
    border-radius: 14px;
    z-index: 999;
    padding: 25px 15px;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    padding: 0 100px;
  }
  input {
    border-radius: 7px;
    font-size: 21px;
    box-shadow: none;
    border: 0.25px solid #e0e0e0;
    height: 62px;
    padding: 2rem 3rem;
    @media (max-width: 1440px) {
      font-size: 16px;
      height: 50px;
    }
    @include respond($phone) {
      font-size: 14px;
      padding: 1rem 2rem;
      height: 35px;
    }
    @include respond($phone) {}
    &::placeholder {
      color: #585858;
      font-size: 21px;
      text-transform: uppercase;
      @media (max-width: 1440px) {
        font-size: 16px;
      }
      @include respond($phone) {
        font-size: 14px;
      }
    }
  }
  .dropdownSelect__filter {
    font-size: 21px;
    color: #585858;
    @media (max-width: 1440px) {
      font-size: 16px;
      padding: 15px;
    }
    @include respond($phone) {
      font-size: 14px;
      padding: 8px;
    }
  }
  .welcome-page__subheader {
    @include respond($phone) {
      text-align: center;
    }
  }
  .buttonInput button {
    @include respond($phone) {
      margin: auto;
    }
  }
  .text__medium--2 {
    text-align: center;
  }
  .text__w300 {
    max-width: 500px;
    text-align: center;
    margin: 15px auto;
  }
}