@import "../../themes/variables";
@import "../../themes/mixins";
@import "../../themes/breakpoints";

$tablet: 768px;

@mixin breakpoint($point) {
  @if $point == untilTablet {
    @media (max-width: $tablet - 1) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: $tablet) {
      @content;
    }
  }
}

.dropdownSelect {
  width: 100%;
  padding-right: 15px;
  @include respond($phone) {
    padding: 0;
    margin-bottom: 15px;
  }

  @include breakpoint(tablet) {
    width: 100%;
    padding-right: 20px;
  }

  &__switch:checked + &__options-filter &__select {
    transform: scaleY(1);
  }

  &__switch:checked + &__options-filter &__filter:after {
    transform: rotate(-135deg);
  }

  &__options-filter {
    width: 100%;
    cursor: pointer;
  }

  &__filter {
    position: relative;
    display: flex;
    padding: 18px;
    background-color: #fff;
    border: 1px solid #d6d6d6;
    border-radius: 7px;
    text-transform: uppercase;
    transition: .3s;

    &:focus {
      outline: none;
    }

    &::after {
      position: absolute;
      top: 40%;
      right: 20px;
      content: '';
      width: 10px;
      height: 10px;
      border-right: 2px solid #595959;
      border-bottom: 2px solid #595959;
      transform: rotate(45deg) translateX(-45%);
      transition: .2s ease-in-out;
    }
  }

  &__select {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 5px;
    overflow: hidden;
    box-shadow: 0 5px 10px 0 rgba(152, 152, 152, 0.6);
    transform: scaleY(0);
    transform-origin: top;
    font-weight: 300;
    transition: .2s ease-in-out;
    z-index: 1;
  }

  &__select-option {
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid #d6d6d6;
    transition: .3s;

    &:last-of-type {
      border-bottom: 0;
    }

    &:hover {
      background-color: #585858;
      color: #FFF;
    }
  }

  ul {
    list-style-type: none;
  }
}
