@import "../variables";
@import "../mixins";
@import "../breakpoints";

.check-box {
    display: flex;
    align-items: flex-start;
    &__label {
        min-width: 20px;
        height: 20px;
        width: 20px;
        border: 2px solid #FE3838;
        border-radius: 3px;
        display: inline-block;
        margin-right: 11px;
        cursor: pointer;
        svg {
            display: none;
        }
    }
    &__input:checked+&__label {
        border: 0;
        //background-color: $color-primary;
        svg {
            display: block;
            min-width: 20px;
            height: 20px;
            border-radius: 3px;
        }
    }
    &__input {
        display: none;
    }
    &__label-text {
        cursor: pointer;
        width: 90%;
        user-select: none;
    }
}