@import "../variables";
@import "../mixins";
@import "../breakpoints";

.text-section {
  @include setMarginTopSection;
  margin-top: 150px;
  margin-bottom: 100px;
  p {
    margin-bottom: 15px;
    line-height: 25px;
    &:first-child {
      margin-bottom: 50px;
    }
  }
}