@import "../variables";
@import "../mixins";
@import "../breakpoints";

.species-menu {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 3.8rem;
        padding: 0 2rem;
        box-shadow: 4px 4px 6px rgba(222, 222, 222, 0.48);
        border-radius: 61px;
        background-color: $color-white;
        margin-right: 20px;
        margin-bottom: 15px;
        &:last-child {
            @include respond-to('medium') {
                margin-right: 0;
            }
        }
        &--active {
            background-color: $color-primary;
            color: $color-white;
        }
        @include respond-to('medium') {
            height: 22px;
            font-size: 11px;
            line-height: 12px;
            font-weight: 300;
            padding: 0 .8rem;
            margin-right: 6px;
        }
    }
    .wppizza-categories {
        @include respond($phone) {
            position: unset;
            background-color: unset;
            box-shadow: unset;
            margin: 0;
            padding: 0;
        }
        li {
            @media (max-width: 900px) {
                width: auto;
            }
        }
    }
}
.wppizza-categories {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
    grid-gap:20px;
    gap: 20px;
    &::-webkit-scrollbar {
        display: none;
      }

    @include respond($phone) {
        flex-wrap: nowrap;
        position: fixed;
        margin-top: 60px;
        padding: 10px 10px 0px 10px;
        top:0;
        left:0;
        z-index:10;
        background-color: $color-white;
        box-shadow: 0 0px 1px 0px #8F8F8F;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        grid-gap: 10px;
        gap: 10px;
    }
    li {
        display: flex;
        margin-right: 32px;
        @media (max-width: 900px) {
            margin-bottom: 15px;
            margin-right: 25px;
        }
        @media (max-width: 900px) {
            margin-right: 0;
            width: max-content;
        }
        &:nth-child(3n + 3) {
            @media (max-width: 650px) {
                margin-right: 0;
            }
        }
        a {
            
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            height: 3.8rem;
            padding: 0 2rem;
            box-shadow: 4px 4px 6px rgba(222, 222, 222, 0.48);
            border-radius: 61px;
            background-color: $color-white;
            @include respond-to('medium') {
                height: 25px;
                font-size: 15px;
                font-weight: 300;
            }
            &[title='active'] {
                background-color: $color-primary;
                color: $color-white
            }
            &:hover {
                background-color: $color-primary;
                color: $color-white
            }
            @media (max-width: 900px) {
                width:max-content;
                padding: 0 1.7rem;
            }
        }
    }
}