@import "../variables";
@import "../mixins";
@import "../breakpoints";

.counter {
    display: flex;
    align-items: center;
    &__minus,
    &__plus {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFE2E2;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 1.1rem;
        &.d-hidden {
            visibility: hidden;
        }
    }
    &__plus {
        color: $color-primary;
        margin-right: 0;
        margin-left: 1.1rem;
    }
}