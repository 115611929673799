@import "../variables";
@import "../mixins";
@import "../breakpoints";

.order-item {
    width: 100%;
    background: $color-white;
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
    &__order-details-wrapper {
        padding: 15px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: start;
        &>* {
            &[name],
            &[price] {
                margin-bottom: 8px;
            }
            &[price] {
                grid-column: 2/ span 1;
                margin-left: 74px;
                @include respond-to('medium') {
                    grid-column: 3 / span 1;
                    justify-self: center;
                }
            }
            &[name] {
                grid-column: 1/ span 1;
            }
            &[header] {
                grid-row: 1 /span 1;
                grid-column: 1/-1;
                margin-bottom: 20px;
            }
            &[btn] {
                justify-self: end;
                @include respond-to('medium') {
                    grid-column: 1 / -1;
                    justify-self: center;
                    margin-top: 35px;
                }
            }
        }
        &[data-isvisible='false'] {
            display: none;
        }
    }
}