@import "../variables";
@import "../mixins";
@import "../breakpoints";

.work-item {
    width: 100%;
    min-height: 63px;
    background-color: $color-white;
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
    padding: 0 15px;
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 650px) {
        flex-wrap: wrap;
    }
    &__description-box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        &>* {
            display: inline-block;
        }
        width: 50%;
        @media (max-width: 650px) {
            width: 100%;
        }
    }
    &__location-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 40%;
        &>* {
            display: inline-block;
        }
        @include respond-to('medium') {
            grid-column: 1 / span 1;
            margin-top: 20px;
        }
    }
    &__description-box,
    &__location-box {
        @include respond-to('medium') {
            grid-column: 1/-1;
        }
    }
    &__text-box {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        row-gap: 10px;
        &>* {
            &:not(:last-child) {
                margin-right: 30px;
            }
        }
        @include respond-to('medium') {
            flex-direction: column;
        }
    }
    &>[btn-return] {
        justify-self: end;
        min-width: 190px;
        @include respond-to('medium') {
            justify-self: center;
            grid-row: 3/ span 1;
            grid-column: 1/-1;
            margin-top: 35px;
        }
    }
    &>[btn] {
        place-self: center;
        justify-self: end;
        min-width: 150px;
        @include respond-to('medium') {
            grid-row: 3/ span 1;
            grid-column: 1/-1;
            justify-self: center;
            margin-top: 35px;
        }
    }
    &--order {
        box-shadow: 0 0 0 0;
    }
    @include respond-to('medium') {
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
        padding: 15px;
    }
}

.fooo {
    height: 150px; // 
    transform: scale(0);
    &[data-show="true"] {
        transform: scale(1);
    }
    // 
}