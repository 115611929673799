@import "../variables";
@import "../mixins";
@import "../breakpoints";

.password-section {
    display: grid;
    grid-template-columns: minmax(200px, 220px) 1fr;
    column-gap: 30px;
    &__right-side {
        max-width: 650px;
    }
    @include respond-to('huge') {
        grid-template-columns: 1fr;
    }
    @include setMarginTopSection;
}

.password-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}