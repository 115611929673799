@import "../variables";
@import "../mixins";
@import "../breakpoints";

.info-page {
    @include setMarginTopSection;
    &__info-box {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 890px;
        // margin-top: 200px;
        gap: 17px;
        &>* {
            margin: 0;
            &[btn] {
                margin: 55px auto 55px auto;
                @include respond-to('medium') {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: $color-primary;
                    color: $color-white !important;
                }
            }
            &[header] {
                margin-bottom: 22px;
                @include respond-to('medium') {
                    color: $color-black !important;
                    font-size: 24px;
                    margin-bottom: 10px;
                }
            }
            &[txt] {
                line-height: 36px;
                @include respond-to('medium') {
                    color: $color-black !important;
                    font-size: 14px;
                }
            }
        }
        @include respond-to('medium') {
            height: calc(100vh - 100px - 41px);
        }
    }
    .wppizza-dbp-form {
        display: none !important;
    }
}

.info-page-wrapper {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}