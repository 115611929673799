@import "../variables";
@import "../mixins";
@import "../breakpoints";

.contact-section {
    &__wrapper {
        @include setMarginTopSection;
        &>* {
            &[header] {
                @include respond($phone) {
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
    }
    &__form-header {
        @include respond($phone) {
            font-size: 12px;
            font-weight: 300;
        }
    }
}

.footer-contact-box {
    width: calc(100% - 256px);
    position: absolute;
    bottom: 0;
    background-color: #FBFBFB;
    @include respond($phone) {
        position: relative;
    }
}