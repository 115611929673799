@import "../variables";
@import "../mixins";
@import "../breakpoints";

.contact-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //margin-top: 19.8rem;
    margin-top: 100px;
    margin-left: 10.7rem;
    max-width: 765px;
    .wppizza-dbp-form {
        display: none !important;
    }
    &.mobile {
        display: none;
        @include respond-to('large') {
            display: flex;
        }
    }
    &.desktop {
        @include respond-to('large') {
            display: none;
        }
    }
    @include respond-to('large') {
        flex-direction: column;
        width: max-content;
        height: auto;
        margin: 3rem auto 0 auto;
        order: 2;
    }
    &__circle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 200px;
        height: 200px;
        box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
        border-radius: 50%;
        background-color: $color-white;
        @include respond-to('large') {
            margin-bottom: 30px;
            width: 180px;
            height: 180px;
        }
        a {
            &:hover {
                color: $color-primary;
            }
        }
    }
    &__description {
        margin-top: 12px;
    }
}