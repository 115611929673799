@import "../variables";
@import "../mixins";
@import "../breakpoints";

.franchise {
  @include setMarginTopSection;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 200px - 41px);
  h1 {
    text-align: center;
  }
  a {
    color: $color-primary;
    @include respond($phone) {
      display: block;
      width: 100%;
    }
    &:hover {
      opacity: .7;
    }
  }
}