@import "../variables";
@import "../mixins";
@import "../breakpoints";

.order-summary {
    margin-top: 120px;
    display: flex;
    &__left-side {
        width: 66%;
        margin-right: 2.4rem;
        @include respond(1300px) {
            width: 100%;
        }
        @include respond($phone) {
            margin-right: 0;
        }
        &>[btn] {
            margin: 40px auto 20px auto;
            width: 200px;
            margin-left: auto;
        }
        .sendOrderForm {
            display: none;
            @include respond(1300px) {
                display: flex;
            }
        }
    }
    &__right-side {
        width: 33%;
        @include respond(1300px) {
            display: none;
        }
    }
    &__contact-box {
        width: 100%;
        background: $color-white;
        box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
        border-radius: 14px;
        padding: 13px 21px;
        margin-top: 28px;
    }
    &__input-group {
        max-width: 465px;
        display: flex;
        flex-direction: column;
        margin-top: 21px;
        .input {
            margin-bottom: 30px;
            &.error {
                .input__input {
                    //border: 1px solid red;
                    &:focus {
                        border: none;
                    }
                }
                .input__error {
                    position: unset;
                    visibility: unset;
                    opacity: 1;
                }
            }
        }
    }
    &__accept-terms {
        margin: 38px 0 40px 21px;
        a {
            color: $color-primary;
            &:hover {
                opacity: .7;
            }
        }
        .error {
            color: $color-primary;
            margin-bottom: 10px;
            display: block;
        }
    }
    @include respond(1070px) {
        margin-top: 80px;
    }
    .wppizza-dbp-form {
        display: none !important;
    }
}