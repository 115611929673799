@import "../variables";
@import "../mixins";
@import "../breakpoints";

.summary-result-box {
    width: 100%;
    background: $color-white;
    border-radius: 14px;
    padding: 13px 18px;
    position: relative;
    &.sticky {
        position: fixed;
        width: 29%;
        @media (max-width: 650px) {
            width: 100%;
        }
    }
    &__items {
        &> :not(:last-child) {
            margin-bottom: 27px;
        }
        img {
            height: 65px;
            width: 65px;
            margin-right: 29px;
        }
    }
    &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    &__item-left {
        display: flex;
    }
    &__item-left-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__item-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }
    &__discount-box {
        position: relative;
        .input {
            &.error {
                .input__input {
                    //border: 1px solid red;

                    &:focus {
                        border: none;
                    }
                }

                .input__error {
                    position: unset;
                    visibility: unset;
                    opacity: 1;
                }
            }
        }
    }
    &__apply-discount-btn {
        position: absolute;
        right: 0;
        top: 0;
    }
    &__break-line {
        width: 99%;
        height: 1px;
        background: $color-grey-light-2;
        margin-bottom: 21px;
    }
    &>* {
        &[header] {
            margin-bottom: 16px;
        }
        &[btn] {
            margin-top: 10px;
            margin-left: auto;
            width: 200px;
        }
    }
    .wppizza-order-wrap {
        //display: none !important;
        .wppizza-fieldset {
            border: 0;
            margin: 0;
        }
        legend, .wppizza-table-header-group, .wppizza-personal-details, .wppizza-payment-methods, .wppizza-ordernow, .wppizza-delivery-note, .wppizza-orders-pickup-choice, .wppizza-item-update, .wppizza_cad, .wppizza-pickup-note {
            display: none !important;
        }
        .wppizza-item-article {
            display: flex;
            align-items: center;
            width: 70%;
        }
        .wppizza-item-title, .wppizza-item-total {
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            padding-right: 20px;
            width: 60%;
            @media (max-width: 1440px) {
                width: 80%;
                font-size: 1.6rem;
            }
        }
        .wppizza-item-size {
            font-size: 1.8rem;
            width: 30%;
            @media (max-width: 1440px) {
                font-size: 1.6rem;
                width: 20%;
            }
        }
        .wppizza-item-price {
            display: none;
        }
        .wppizza-item-total {
            font-size: 1.8rem;
            font-weight: 500;
            width: 20%;
            text-align: left !important;
            @media (max-width: 1440px) {
                width: 25%;
                font-size: 1.6rem;
            }
        }
        .wppizza-item-delete {
            display: none;
            input {
                border: 0;
                background: 0 0;
                cursor: pointer;
                font-size: 120%!important;
                padding: 3px 8px!important;
                line-height: 1.15!important;
            }
        }
        .wppizza-item-row {
            border-top: 1px solid #cecece;
            display: flex;
            margin-top: 20px;
            padding: 7px 0px;
            justify-content: space-between;
            &:first-child {
                margin-top: 0;
            }
        }
        .wppizza-order-itemised {
            td {
                padding: 0 !important;
            }
            .wppizza-item-row {
                > td {
                    border: 0 !important;
                }
            }
        }
        .wppizza-item-delete {
            margin-right: 20px;
        }
        .wppizza-order-summary {
            width: 100%;
            margin-top: 50px;
            @media (max-width: 1440px) {
                margin-top: 10px;
            }
        }
        .wppizza-total-items, .wppizza-delivery, .wppizza-total, .wppizza-discount, .wppizza-wppizza_cad_coupon {
            margin: 5px 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            @media (max-width: 1440px) {
                margin: 2px 0;
            }
                td {
                    font-size: 1.8rem;
                    height: 31px;
                    font-weight: 500;
                    @media (max-width: 1440px) {
                        font-size: 1.6rem;
                    }
            }
        }
        .wppizza-total {
            margin-top: 15px;
        }
        table.wppizza-order-itemised > tbody {
            display: block;
            height: 350px;
            overflow: scroll;
            padding-right: 5px;
            @media (max-width: 1600px) {
                height: 270px;
            }
            @media (max-width: 1440px) {
                height: 200px;
            }
            &::-webkit-scrollbar {
                //background-color: #888;
                border-radius: 20px;
                width: 6px;
                -webkit-appearance: none;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #fe3838;
                border-radius: 20px;
            }

        }
    }
    input {
        background-color: $color-white;
        padding: 2rem 1.2rem;
        border-radius: 999px;
        border: none;
        -webkit-appearance: none;
        -webkit-box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
        box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
        height: 4.6rem;
        width: 100%;
        font-size: 1.4rem;
        font-weight: 400;
        &::placeholder {
            color: #585858;
            font-size: 14px;
        }
        &:focus {
            outline: none;
        }
    }
    .wppizza-login {
        overflow: unset;
        display: none;
    }
    #wppizza_cad_coupons_remove {
        display: none;
    }
}