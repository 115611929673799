@import "../variables";
@import "../mixins";
@import "../breakpoints";

.background-contact {
    @include setBackground;
    &__pizza {
        @include setElementInBackground('../assets/background-images-desktop/pizza.png', 35%, 100%, right, 35%) {
            right: 0;
            top: 0;
            z-index: 30;
            @include respondBckgItem(1070px, 45%, 100%);
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__tomato-1 {
        @include setElementInBackground('../assets/background-images-desktop/tomato1.png', 210px, 210px, right, 210px) {
            left: 57%;
            bottom: 18%;
            z-index: 30;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__tomato-2 {
        @include setElementInBackground('../assets/background-images-desktop/tomato2.png', 180px, 180px, right, 180px) {
            left: 52%;
            bottom: 8%;
            z-index: 30;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__basil {
        @include setElementInBackground('../assets/background-images-desktop/basil-full.png', 20%, 100%, right, 20%) {
            left: 10%;
            top: 31%;
            z-index: 30;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
}