@import "../variables";
@import "../mixins";
@import "../breakpoints";

.background-index {
    background-color: #FFF;
    @include setBackground;
    &__top {
        @include setElementInBackground('../assets/background-images-desktop/bckg-shape-top.svg', 60%, 100%, top, 60%) {
            right: 0;
            top: 0;
            z-index: 20;
            @include respondBckgItem(1220px, 77%, 100%);
            @include respondBckgItem(900px, 83%, 100%);
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__bottom {
        @include setElementInBackground('../assets/background-images-desktop/bckg-shape-bottom.svg', 32%, 100%, bottom right, 32%) {
            right: 0;
            bottom: 0;
            z-index: 20;
            @include respondBckgItem(1220px, 47%, 100%);
            @include respondBckgItem(900px, 50%, 100%);
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__pizza {
        @include setElementInBackground('../assets/background-images-desktop/pizza.png', 35%, 100%, right, 35%) {
            right: 0;
            top: 0;
            z-index: 30;
            @include respondBckgItem(1220px, 48%, 100%);
            @include respondBckgItem(900px, 60%, 100%);
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__tomatoes {
        @include setElementInBackground('../assets/background-images-desktop/tomatoes.png', 40%, 100%, bottom, 40%) {
            right: 35%;
            bottom: 0;
            z-index: 30;
            @include respondBckgItem(900px, 50%, 100%) {
                right: 25%;
            }
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__basil {
        @include setElementInBackground('../assets/background-images-desktop/basil-index.png', 27%, 100%, bottom left, 27%) {
            left: 0;
            bottom: -10px;
            z-index: 30;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__basil-mobile {
        display: none;
        @include setElementInBackground('../assets/background-images-mobile/basil.png', 172px, 172px, right, 172px) {
            right: 0;
            bottom: 0;
            @include respond-to('medium') {
                display: block;
            }
        }
    }
    &__tomato {
        display: none;
        @include setElementInBackground('../assets/background-images-mobile/tomato.png', 155px, 155px, right, 155px) {
            right: 0;
            top: 40%;
            z-index: 30;
            @include respond-to('medium') {
                display: block;
            }
        }
    }
}