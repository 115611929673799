@import "../variables";
@import "../mixins";
@import "../breakpoints";

.menu-item {
    position: relative;
    width: 100%;
    background-color: $color-white;
    border-radius: 14px;
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
    &__button {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 64px;
        height: 64px;
        background-color: $color-primary;
        border: none;
        border-bottom-right-radius: 14px;
        border-top-left-radius: 14px;
        box-shadow: 4px 4px 10px rgba(232, 173, 22, 0.13);
        outline: none;
        cursor: pointer;
        &:hover {
            &>:first-child {
                transform: scale(0.9);
            }
        }
        @include respond($phone) {
            width: 40px;
            height: 40px;
        }
    }
    &__img {
        width: 100%;
        border-radius: 14px 14px 0 0;
        @include respond(760px) {
            object-fit: cover;
            height: 9rem;
        }
    }
    &__size-input:checked &__size {
        border: 1px solid $color-primary;
        background-color: $color-primary;
        border-radius: 10px;
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 15px;
        &>* {
            &[price] {
                margin-bottom: 11px;
                margin-top: 19px;
            }
            &[size-menu] {
                margin-top: 20px;
            }
            &[name] {
                margin-bottom: 7px;
            }
        }
    }
    &__name {
        line-height: 38px;
    }
    &__description {
        text-align: left;
        line-height: 20px;
    }
    &__price {
        line-height: 45px;
        font-size: 30px;
        @include respond($phone) {
            font-size: 21px;
            line-height: 30px;
        }
    }
    &__size-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 48px;
        box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
        border-radius: 14px;
        border: 1px solid $color-primary;
        overflow: hidden;
        &>* {
            &[check] {
                display: none;
            }
        }
        &.drink {
            flex-wrap: wrap;
            height: max-content;
            transition: 1s;
            &.open {
                svg {
                    display: none;
                }
                .menu-item__size {
                    display: flex;
                }
            }
            .menu-item__size {
                width: 100%;
                border: 0;
                position: relative;
                display: none;
                cursor: pointer;
                &--active {
                    display: flex;
                }
            }
            svg {
                position: absolute;
                top: 18px;
                right: 15px;
                path {
                    stroke: $color-white;
                }
            }
        }
    }
    &__size {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        cursor: pointer;
        height: 48px;
        //border: 1px solid transparent;
        &:not(:last-child) {
            border-right: 1px solid $color-primary;
        }
        &--active {
            border: 1px solid $color-primary;
            background-color: $color-primary;
            //border-radius: 10px;
            color: $color-white;
            cursor: default;
        }
        &:checked {
            border: 1px solid $color-primary;
            background-color: $color-primary;
            border-radius: 10px;
        }
    }
    &__sticks-box {
        width: 100%;
        height: 100%;
        position: relative;
    }
    &__vertical-stick,
    &__horizontal-stick {
        position: absolute;
        width: 4px;
        height: 30px;
        background: $color-white;
        top: 50%;
        left: 50%;
        @include respond($phone) {
            height: 25px;
        }
    }
    &__horizontal-stick {
        transform: translate(-50%, -50%) rotate(90deg);
    }
    &__vertical-stick {
        transform: translate(-50%, -50%);
    }
}