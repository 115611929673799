.app {
  background-color: #f3f2f1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app__body {
  display: flex;
  width: 100%;
  max-width: 1200px;
}