@import "../variables";
@import "../mixins";
@import "../breakpoints";

.customize-result {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: $color-white;
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
    border-radius: 14px;
    padding: 25px;
    margin-top: 24px;
    &.sticky {
        position: fixed;
        width: 700px;
        @media (max-width: 1440px) {
            width: 600px;
        }
        @media (max-width: 650px) {
            position: unset;
            width: 100%;
        }
    }
    @include respond($phone) {
        margin-top: 0;
        padding: 25px 0;
        background: transparent;
    }
    &__result-item {
        display: flex;
        margin-top: 25px;
        @include respond($phone) {
            flex-wrap: wrap;
            margin-top: 15px;
        }
    }
    &__result-type {
        margin-right: 20px;
        min-width: 200px;
        @include respond($phone) {
            width: 100%;
            font-size: 14px;
            font-weight: 600;
        }
    }
    @include respond($phone) {
        //display: none;
    }
    h3 {
        @include respond($phone) {
            font-size: 14px;
            font-weight: 700;
        }
    }
}