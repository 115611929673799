@import "../variables";
@import "../mixins";
@import "../breakpoints";

.customize-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    @include respond($phone) {
        margin-bottom: 10px;
    }
    &__side-box {
        display: flex;
        .counter {
            margin-left: 48px;
        }
    }
    &__name,
    &__price,
    &__item-count {
        @include respond-to('medium') {
            font-size: 12px !important;
            font-weight: 300 !important;
        }
    }
    &[data-category="sosy-skladniki"] {
        display: none;
    }
}