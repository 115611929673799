@import "../variables";
@import "../mixins";
@import "../breakpoints";

.blured-background {
    //display: none;
    position: fixed;
    z-index: 700;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    //background-color: rgba(0, 0, 0, 0.2);
    @include respond($phone) {
      position: unset;
    }
    &[data-blured='true'] {
        display: block;
    }
        &::-webkit-scrollbar {
                //display: none;
        }
}