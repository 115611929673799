@import "../variables";
@import "../mixins";
@import "../breakpoints";

.about-section {
    margin-top: 211px;
    &__header {
        margin-bottom: 45rem;
        padding-right: 40%;
        @include respond-to('medium') {
            margin-bottom: 40px;
            height: auto;
            padding-right: 0;
        }
    }
    &__main-text {
        @include respond($phone) {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 50px;
        }
    }
    &__sub-text {
        @include respond($phone) {
            font-size: 14px;
            font-weight: 700;
        }
    }
    &__text {
        box-sizing: border-box;
        line-height: 32px;
        @include respond($phone) {
            font-size: 12px;
            font-weight: 400;
            line-height: 28px;
        }
    }
    &__article-header {
        margin-bottom: 36px;
        &--1 {
            @include respond($phone) {
                font-size: 12px;
                margin-bottom: 60px;
            }
        }
    }
    &__article {
        max-width: 100%;
        position: relative;
        &--1 {
            margin-bottom: 20.5rem;
        }
        &--2 {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            @include respond-to('medium') {
                margin-bottom: 140px;
            }
        }
    }
    &__tomatoes-article-bottom {
        @include setElementInBackground('../assets/background-images-mobile/tomatoes-about-mobile.png', 200px, 200px, left, 200px) {
            display: none;
            bottom: -140px;
            margin-left: -16px;
        }
        @include respond-to('medium') {
            display: block;
        }
    }
    &__vegetables-article-bottom {
        @include setElementInBackground('../assets/background-images-mobile/vegetables-about-mobile.png', 200px, 200px, center, 200px) {
            display: none;
            right: -30px;
            clip-path: circle(40%);
        }
        @include respond-to('medium') {
            display: block;
        }
    }
    &__basil-article-bottom {
        @include setElementInBackground('../assets/background-images-mobile/basil-about-mobile.png', 221px, 221px, center, 221px) {
            display: none;
            right: 40px;
            z-index: -1;
        }
        @include respond-to('medium') {
            display: block;
        }
    }
    &__article-right {
        flex-grow: 1;
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @include respond($phone) {
            align-items: flex-start;
            text-align: left;
            margin-left: 0;
        }
    }
    &__vegetables {
        margin-bottom: -15rem;
        margin-bottom: 15rem;
        margin-left: 40px;
        width: 35%;
        border-radius: 50%;
        background-color: $color-secondary;
        @include respond($phone) {
            display: none;
        }
    }
    @include respond-to('huge') {
        margin-top: 140px;
    }
    @include respond-to('medium') {
        margin-top: 90px;
    }
}