@import "../variables";
@import "../mixins";
@import "../breakpoints";

.summary-deliver-box {
    width: 100%;
    background: $color-white;
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
    border-radius: 14px;
    margin-top: 27px;
    padding: 13px 20px;
    &__option {
        display: flex;
        align-items: center;
        label {
            span {
                font-weight: 500;
            }
        }
    }
    &__navbar {
        display: flex;
        a {
            margin-right: 70px;
            @include respond-to('medium') {
                margin-right: 30px;
            }
            &:last-child {
                @include respond-to('medium') {
                    margin-right: 0;
                }
            }
        }
    }
    &__navbar-link {
        color: $color-grey-light-2;
        border-radius: 116px;
        padding: 8px 11px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        &--active {
            color: $color-white;
            border-radius: 116px;
            padding: 8px 16px;
            height: auto;
            background-color: $color-primary;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                color: $color-white;
            }
        }
        &:hover {
            color: $color-white;
            background-color: $color-primary;
        }
    }
    &>* {
        &[navbar] {
            margin-bottom: 21px;
        }
        &[checkbox] {
            margin-bottom: 23px;
        }
        &[info] {
            display: block;
            margin-bottom: 11px;
        }
        &[additional] {
            display: block;
            margin-bottom: 19px;
        }
    }
    .input {
        margin-bottom: 30px;
        margin-right: 15px;
        &.error {
            .input__input {
                //border: 1px solid red;
                &:focus {
                    border: none;
                }
            }
            .input__error {
                position: unset;
                visibility: unset;
                opacity: 1;
            }
        }
    }
    .ownDeliveryOption {
        width: 100%;
        .summary-deliver-box__add-info {
            display: block;
            margin-bottom: 15px;
        }
    }
}