@import "../variables";
@import "../mixins";
@import "../breakpoints";

.modal {
    display: none;
    position: fixed;
    z-index: 999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.32);
    &__content {
        @include absPostionCenter;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: $color-white;
        max-width: 782px;
        width: 80%;
        min-height: 314px;
        border-radius: 14px;
        z-index: 999;
        padding: 25px 15px;
        &>[btn] {
            align-self: center;
        }
        button {
            margin: auto;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        //gap: 20px;
        margin-bottom: 30px;
        &>* {
            margin: 0;
            &[link] {
                text-decoration: underline solid $color-primary;
            }
        }
    }
    &__form {
        margin-bottom: 25px;
        &[data-is-active='false'] {
            display: none;
        }
    }
    .mrg-bottom-30 {
        @include respond-to('medium') {
            margin-bottom: 15px !important;
        }
    }
    &.confirm-modal {
        .modal__content {
            min-height: 250px;
        }
        .modal__buttons {
            display: flex;
            justify-content: flex-end;
            @include respond-to('medium') {
                flex-wrap: wrap;
            }
            button {
                margin-left: 30px;
                @include respond-to('medium') {
                    margin: 0;
                    width: 100%;
                }
                &:last-child {
                    @include respond-to('medium') {
                        margin-top: 15px;
                    }
                }
            }
        }
        .text__w300 {
            line-height: 26px;
        }
    }
    &.confirm-modal-delete {
        .modal__content {
            min-height: 250px;
        }
        .modal__buttons {
            display: flex;
            justify-content: flex-end;
            @include respond-to('medium') {
                flex-wrap: wrap;
            }
            button {
                margin-left: 30px;
                @include respond-to('medium') {
                    margin: 0;
                    width: 100%;
                }
                &:last-child {
                    @include respond-to('medium') {
                        margin-top: 15px;
                    }
                }
            }
        }
        .text__w300 {
            line-height: 26px;
        }
    }
    &.order-empty-modal {
        .modal__content {
            min-height: 250px;
        }
        .modal__buttons {
            display: flex;
            justify-content: flex-end;
            @include respond-to('medium') {
                flex-wrap: wrap;
            }
            button {
                margin-left: 30px;
                @include respond-to('medium') {
                    margin: 0;
                    width: 100%;
                }
                &:last-child {
                    @include respond-to('medium') {
                        margin-top: 15px;
                    }
                }
            }
        }
        .text__w300 {
            line-height: 26px;
        }
        .mrg-bottom-30 {
            margin-bottom: 25px;
        }
    }
    .text__medium--2 {
        text-align: center;
    }
    .text__w300 {
        max-width: 500px;
        text-align: center;
        margin: 15px auto;
    }
}
.open-modal {
    display: block;
}