@import "../../themes/variables";
@import "../../themes/mixins";
@import "../../themes/breakpoints";

.sidebar {
  position: relative;
  min-width: 270px;
  min-height: 100vh;
  width: 100%;
  background: $color-primary;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  //  animation: fadeInLeft .4s ease-out;
  transition: transform .4s ease-out;
  transform: translateX(-150%);
  padding: 45px 20px 15px 20px;
  z-index: 9999;
  &>* {
    &[hamburger] {
      display: flex;
    }
    &[arrow] {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 27px;
      left: 15px;
      &[data-login='true'] {
        display: block;
      }
      &:hover {
        transform: scale(0.95);
      }
    }
    &[menu] {
      margin-bottom: 12px;
    }
    &[footer] {
      padding: 15px 0;
    }
  }
  &[data-show='true'] {
    transform: translateX(0);
  }
  &__main {
    width: 100%;
    height: 78vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &[data-login="true"] {
      display: none;
    }
  }
  &__menu {
    display: flex;
    flex-direction: column;
    a {
      margin-bottom: 20px;
      &:last-child {
        margin: 0;
      }
    }
    .text__small {
      margin-bottom: 12px;
      &:last-child {
        margin: 0;
      }
    }
  }
  &__break {
    width: 100%;
    height: 1px;
    background: $color-white;
  }
  &__icon-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 5px;
  }
  &__shopping-box {
    display: flex;
    flex-direction: column;
    &>[box] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        height: 46px;
        display: flex;
        flex-wrap: wrap;
        span, h3 {
          width: 100%;
          line-height: 23px;
        }
      }
    }
  }
}