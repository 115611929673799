.sign-in-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  margin-left: 1.2rem;
  margin-right: 2.5rem;

  & > :first-child {
    margin-bottom: 3.6rem;
  }

  &__forget {
    align-self: flex-end;
    margin-top: 9px;
  }
}
