@import "../variables";
@import "../mixins";
@import "../breakpoints";

.background-user {
    @include setBackground;
    &__top {
        @include setElementInBackground('../assets/background-images-desktop/bckg-shape-top.svg', 50%, 100%, top, 50%) {
            right: 0;
            top: 0;
            z-index: 20;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__top-password {
        @include setElementInBackground('../assets/background-images-desktop/bckg-shape-top.svg', 42%, 100%, top, 42%) {
            right: 0;
            top: 0;
            z-index: 20;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__pizza {
        @include setElementInBackground('../assets/background-images-desktop/pizza.png', 35%, 100%, right, 35%) {
            right: -200px;
            top: 0;
            z-index: 30;
            @include respondBckgItem(1220px, 48%, 100%);
            @include respondBckgItem(900px, 60%, 100%);
            @include respond-to('medium') {
                display: none;
            }
        }
    }
}