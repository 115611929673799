@import "../variables";
@import "../mixins";
@import "../breakpoints";

.login-card {
    position: fixed;
    width: 29.5rem;
    //height: 47.5rem;
    background-color: $color-white;
    border-radius: 7px;
    right: 3rem;
    top: 6rem;
    display: none;
    padding: 2.4rem 3rem;
    transform: translateX(500px);
    transition: all 0.5s;
    &[data-show='true'] {
        display: block;
        transform: translate(0);
        z-index: 2;
    }
    &[data-login='true'] {
        margin-top: 67px;
        max-width: 26.7rem;
        position: static;
        display: block;
        transform: translate(0);
    }
    &__content, #myloginform {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        border: 0;
        &>* {
            &[login-btn] {
                margin-top: 46px;
                margin-bottom: 18px;
            }
            &[input] {
                &:first-child {
                    margin-bottom: 3.6rem;
                }
            }
            &[resset] {
                align-self: flex-end;
            }
            &[icons] {
                min-width: 16.3rem;
                display: flex;
                justify-content: space-between;
                margin-left: auto;
                margin-right: auto;
            }
            &[break-line] {
                align-self: center;
                margin-bottom: 33px;
            }
        }
        input {
            background-color: $color-white;
            padding: 2rem 1.2rem;
            border-radius: 999px !important;
            border: none;
            -webkit-appearance: none;
            -webkit-box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
            box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
            height: 4.6rem;
            width: 100%;
            font-size: 1.4rem;
            font-weight: 400;
            &::placeholder {
                color: #585858;
                font-size: 14px;
            }
            &:focus {
                outline: none;
            }
        }
        label {
            position: absolute;
            top: 0;
            left: 2.4rem;
            transition: all 0.3s;
            transform: translate(0, -50%);
            font-size: 10px !important;
            visibility: visible;
            opacity: 1;
            z-index: 1;
            font-weight: 400 !important;
            margin: 0 !important;
        }
        .login-username, .login-password {
            position: relative;
            margin-bottom: 3.6rem;
        }
        .login-remember {
            display: none;
        }
        .login-submit {
            align-self: center;
            input {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all 0.3s;
                background-color: $color-primary;
                color: #fff !important;
                border: none;
                border-radius: 30px;
                padding: 8px 15px;
                font-weight: 600;
                font-size: 16px;
                height: 4.6rem;
                outline: none;
                transition: all 0.1s;
                box-shadow: 4px 4px 10px rgba(232, 173, 22, 0.13);
                &:hover {
                    transform: scale(1.02);
                }
                &:active {
                    transform: scale(0.98);
                }
            }
        }
    }
    .user-registration {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        border: 0;
        .input-text {
            background-color: $color-white;
            padding: 2rem 1.2rem;
            border-radius: 999px !important;
            border: none;
            -webkit-appearance: none;
            -webkit-box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
            box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
            height: 4.6rem;
            width: 100%;
            font-size: 1.4rem;
            font-weight: 400;
            &::placeholder {
                color: #585858;
                font-size: 14px;
            }
            &:focus {
                outline: none;
            }
        }
        .ur-label {
            position: absolute;
            top: 0;
            left: 2.4rem;
            transition: all 0.3s;
            transform: translate(0, -50%);
            font-size: 10px !important;
            visibility: visible;
            opacity: 1;
            z-index: 1;
            font-weight: 400 !important;
            margin: 0 !important;
        }
        .form-row {
            position: relative;
            margin-bottom: 3.6rem;
        }
    }
    &__break-line {
        margin-top: 49px;
        margin-left: auto;
        margin-right: auto;
        font-size: 12px;
        color: $color-grey-light;
        position: relative;
        &::before,
        &::after {
            content: '';
            width: 39px;
            height: 1px;
            background-color: #b3b3b3;
            position: absolute;
            z-index: 1;
            top: 50%;
        }
        &::before {
            left: -53px;
        }
        &::after {
            right: -53px;
        }
    }
}
#switchRegister, #switchRegisterMobile {
    margin: 0 auto;
}