@import "../variables";
@import "../mixins";
@import "../breakpoints";

.shopping-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 470px;
    background-color: $color-white;
    border-radius: 14px;
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
    position: relative;
    padding: 15px 0;
    &.sticky {
        position: fixed;
        width: 400px;
        top: 200px;
        @media (max-width: 1440px) {
            top: 100px;
        }
        @media (max-width: 650px) {
            width: 100%;
        }
    }
    @include respond(900px) {
        height: auto;
    }
    &>* {
        &[icon] {
            margin: 33px 0;
        }
        &[name] {
            margin-bottom: 15px;
        }
    }
    &__header {
        font-size: 18px;
        font-weight: 500;
    }
    &__option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 15px;
    }
    &__content {
        width: calc(100% - 42px);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        //padding-left: 27px;
        &>* {
            &[input] {
                margin-top: 65px;
            }
            &[button] {
                margin-top: 22px;
                align-self: flex-end;
            }
        }
        #productCartItems {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
        }
    }
    &__icon {
        width: 55px;
    }
    &__item {
        width: 100%;
        margin-left: 55px;
        padding: 5px 0 15px 0;
        border-bottom: 1px solid #979797;
        position: relative;
    }
    &__item-box {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
    }
    &__item-info,
    &__item-details,
    &__result-box {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        align-self: flex-end;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        .counter {
            display: none;
        }
    }
    &__item-details {
        font-weight: 300;
    }
    &__item-size {
        line-height: 20px;
        font-size: 1.4rem;
        font-weight: 300;
    }
    &__break {
        width: 100%;
        height: 1px;
        background-color: #979797;
        margin: 21px 0 12px 0;
    }
    &__delete-item {
        position: absolute;
        left: -21px;
        top: 8px;
        font-size: 20px;
        cursor: pointer;
        &:hover {
            opacity: .7;
        }
    }
    .wppizza-cart {
        border: 0;
        min-height: auto;
        width: 100%;
        padding: 0 !important;
        //display: none;
        .wppizza-cart-info {
            .wppizza-cart-buttons {
                display: none;
            }
        }
        .wppizza-delivery, .wppizza-total-items {
            display: none;
        }
        .wppizza-order-itemised {
            border: 0;
            tbody {
                height: auto;
            }
            .wppizza-item-row {
                > td {
                    border-top: 0;
                }
            }
        }
        .wppizza-item-title, .wppizza-item-total {
            font-size: 18px;
            font-weight: 500;
            line-height: 30px;
        }
        .wppizza-item-size {
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
            width: 100%;
        }
        .wppizza-item-article, .wppizza-item-total {
            display: flex;
            flex-wrap: wrap;
            padding: 5px 0 15px 0;
        }
        .wppizza-item-row {
            position: relative;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #979797;
        }
        .wppizza-min-order {
            display: none;
        }
        .wppizza-item-row-ingredients {
            border-bottom: 1px solid #979797;
        }
        .wppizza-item-quantity {
            position: absolute;
            right: 0;
            top: 35px;
            min-width: 60px;
            padding: 0;
            border: 0;
            .ui-spinner {
                position: relative;
                width: 100% !important;
                border: 0;
                &-down {
                    position: absolute;
                    left: 0;
                    top: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #FFE2E2;
                    height: 16px !important;
                    width: 16px !important;
                    border-radius: 50%;
                    cursor: pointer;
                    .ui-button-icon {
                        background-image: none;
                        &:before {
                            content: '';
                            position: absolute;
                            left: 5px;
                            top: 7px;
                            right: 5px;
                            height: 1px;
                            color: black;
                            font-size: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 1;
                            border-top: 1px solid;
                        }
                    }
                }
                &-up {
                    position: absolute;
                    right: 0;
                    top: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #FFE2E2;
                    height: 16px !important;
                    width: 16px !important;
                    border-radius: 50%;
                    cursor: pointer;
                    .ui-button-icon {
                        background-image: none;
                        &:before {
                            content: '';
                            position: absolute;
                            left: 4px;
                            top: 7px;
                            right: 5px;
                            height: 1px;
                            color: black;
                            font-size: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 1;
                            border-bottom: 1px solid #fe3838;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            left: 7px;
                            top: 4px;
                            bottom: 5px;
                            height: 7px;
                            color: black;
                            font-size: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            z-index: 1;
                            border-right: 1px solid #fe3838;
                        }
                    }
                }
                &-input {
                    text-align: center;
                    padding: 0 !important;
                    width: 100%;
                    &:focus {
                        border: 0;
                        outline: 0;
                    }
                }
            }
        }
        .wppizza-item-delete {
            position: absolute;
            left: -40px;
            top: 0;
            input {
                border: 0;
                background: transparent;
                cursor: pointer;
                font-size: 110% !important;
                padding: 3px 8px !important;
                line-height: 1.15 !important;
            }
            img {
                width: 35px;
                object-fit: contain;
                cursor: pointer;
            }
        }
        .wppizza-table-row-group {
            overflow: unset;
        }
        .wppizza-item-total {
            width: auto !important;
        }
        .wppizza-total {
            td {
                font-size: 18px;
                font-weight: 500;
                line-height: 30px;
            }
        }
        .wppizza-order-summary {
            //display: none;
            width: 100%;
            margin-bottom: 0 !important;
        }
        .wppizza-discount, .wppizza-wppizza_cad_coupon {
            display: flex;
            justify-content: space-between;
            width: 100%;
            td {
                font-size: 18px;
                font-weight: 500;
                line-height: 30px;
                padding: 0 !important;
            }
        }
        .wppizza-total {
            display: none;
            margin-top: 15px;
        }
        table.wppizza-order-itemised > tbody {
            max-height: 325px;
            overflow: scroll;
            padding-right: 5px;
            padding-left: 27px;
            @media (max-width: 1440px) {
                max-height: 275px;
            }
            @media (max-width: 900px) {
                max-height: unset;
                height: auto;
                overflow: unset;
                padding-right: 0;
            }
            &::-webkit-scrollbar {
                //background-color: #888;
                border-radius: 20px;
                width: 6px;
                -webkit-appearance: none;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #fe3838;
                border-radius: 20px;
            }

        }
    }
    .wppizza-orders-pickup-choice, .wppizza-pickup-note {
        display: none;
    }
}

