@import "../variables";
@import "../mixins";
@import "../breakpoints";

.localizations-section {
    @include setMarginTopSection;
    margin-bottom: 10px;
    &__circle-box {
        //@extend .main-container;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: space-between;
        margin-bottom: 9.6rem;
        gap: 5rem 4rem;
        @include respond-to('max') {
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
        }
        @include respond-to('medium') {
            grid-template-columns: 1fr;
            justify-items: center;
        }
        @include respond-to('small') {
            margin-top: 8rem;
        }
    }
    &__circle {
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: space-around;
        box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
        width: 280px;
        height: 280px;
        border-radius: 50%;
        background: $color-white;
        padding: 50px 7px;
        &__header {
            line-height: 36px;
            color: $color-primary;
            cursor: pointer;
            margin-bottom: 20px;
            @include respond-to('small') {
                margin-bottom: 0;
            }
            &:hover {
                color: $color-black;
            }
        }
        &__phone {
            line-height: 30px;
        }
        &__adress {
            line-height: 30px;
        }
        &__phone,
        &__adress,
        &__header {
            @include respond(400px) {
                font-size: 12px;
            }
        }
        @include respond-to('small') {
            width: 170px;
            height: 170px;
            padding: 15px 7px;
        }
        a {
            &:hover {
                color: $color-primary;
            }
        }
    }
    &__map {
        margin-bottom: 30px;
    }
}