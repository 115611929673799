@import "../variables";
@import "../mixins";
@import "../breakpoints";

.job-descriptions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 650px) {
        flex-wrap: wrap;
    }
    &__tasks-ul-box,
    &__expectations-ul-box {
        justify-self: start;
        width: 50%;
        @media (max-width: 650px) {
            width: 100%;
        }
    }
    &__ul-header {
        margin-bottom: 22px;
    }
    &__tasks-list {
        line-height: 42px;
        margin-left: 20px;
        &>*::marker {
            color: $color-primary;
            width: 8px;
            height: 8px;
        }
    }
}