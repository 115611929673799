@import "../variables";
@import "../mixins";
@import "../breakpoints";

.background-customize-pizza {
    @include setBackground;
    &__top {
        //@include setElementInBackground('../assets/background-images-desktop/bckg-shape-top.svg', 60%, 100%, top) {
        //    right: 0;
        //    top: 0;
        //    z-index: 20;
        //    @include respondBckgItem(1220px, 77%, 100%);
        //    @include respondBckgItem(900px, 83%, 100%);
        //    @include respond-to('medium') {
        //        display: none;
        //    }
        //}
    }
}