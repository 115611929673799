@import "../variables";
@import "../mixins";
@import "../breakpoints";

.background-localization {
    @include setBackground;
    &__black-pepper {
        @include setElementInBackground('../assets/background-images-desktop/black-pepper.png', 25%, 80%, right, 25%) {
            right: 0;
            top: 0;
            z-index: 20;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__black-pepper-mobile {
        @include setElementInBackground('../assets/background-images-mobile/pepper-localization-mobile.png', 230px, 270px, right, 230px) {
            display: none;
            right: 0;
            top: 150px;
            z-index: 20;
            @include respond-to('medium') {
                display: block;
            }
        }
    }
    &__basil {
        @include setElementInBackground('../assets/background-images-mobile/basil-localization.png', 243px, 243px, left, 243px) {
            display: none;
            left: 0;
            bottom: 0;
            z-index: 20;
            @include respond-to('medium') {
                display: block;
            }
        }
    }
}