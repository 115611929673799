@import "../variables";
@import "../mixins";
@import "../breakpoints";

.info-page-background {
    @include setBackground;
    &__shape-top {
        @include setElementInBackground('../assets/background-images-desktop/shape-top-left.svg', 60%, 100%, top left, 60%) {
            left: 0;
            top: 0;
            z-index: 20;
            min-width: 1100px;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__pizza {
        @include setElementInBackground('../assets/background-images-desktop/pizza.png', 40%, 100%, right bottom, 40%) {
            right: 0;
            bottom: -200px;
            z-index: 30;
            min-width: 550px;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__basil {
        @include setElementInBackground('../assets/background-images-desktop/basil-full.png', 45%, 45%, bottom right, 45%) {
            left: 20%;
            bottom: -150px;
            z-index: 20;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__tomato {
        @include setElementInBackground('../assets/background-images-desktop/tomato2.png', 20%, 45%, top left, 20%) {
            left: 20%;
            bottom: -170px;
            z-index: 20;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__tomato-1 {
        @include setElementInBackground('../assets/background-images-desktop/tomato1.png', 20%, 45%, top right, 20%,) {
            left: 40%;
            bottom: 0;
            z-index: 20;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__pizza-mobile {
        @include setElementInBackground('../assets/background-images-mobile/pizza-mobile-info.png', 50%, 45%, top right, 50%) {
            display: none;
            right: 0;
            bottom: 150px;
            z-index: 20;
            @include respond-to('medium') {
                display: block;
            }
        }
    }
    &__basil-mobile {
        @include setElementInBackground('../assets/background-images-mobile/basil.png', 30%, 30%, top right, 30%) {
            display: none;
            right: 0;
            top: 50px;
            z-index: 20;
            &--confirm {
                top: 200px;
            }
            @include respond-to('medium') {
                display: block;
            }
        }
    }
    &__tomato-mobile {
        @include setElementInBackground('../assets/background-images-mobile/tomato-mobile-info.png', 25%, 45%, top right, 25%) {
            display: none;
            left: 0;
            bottom: -100px;
            z-index: 20;
            @include respond-to('medium') {
                display: block;
            }
        }
    }
}