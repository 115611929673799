@import "../variables";
@import "../mixins";
@import "../breakpoints";

.dropdown {
    position: relative;
    margin-right: auto;
    margin-left: 20px;
    &__value {
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        span {
            margin-left: 10px;
        }
    }
    &__header {
        @include respond-to('medium') {
            font-size: 15px;
            font-weight: 500;
        }
    }
    &__headerSub {
        width: 100%;
    }
    &__arrow {
        margin-left: 10px;
        height: auto !important;
        transition: all 0.3s;
    }
    &__list {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //gap: 18px;
        background-color: $color-white;
        box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
        border-radius: 14px;
        padding: 15px;
        z-index: 9999999999;
        margin-top: 15px;
        min-width: 190px;
        &--order {
            padding: 10px 5px;
        }
        &-phone {
            margin-top: 5px;
            margin-bottom: 15px;
            font-size: 12px;
            font-weight: 300;
            &:last-child {
                margin-top: 5px;
                margin-bottom: 0;
            }
            &:hover {
                color: $color-primary;
            }
        }
    }
    &__list-item {
        cursor: pointer;
        font-size: 15px;
        font-weight: 400;
        &:hover {
            color: $color-primary;
        }
    }
    &[data-is-open='false'] &__list {
        display: none;
        transform: translateY(-110%);
    }
    &[data-is-open='true'] &__value &__arrow {
        transform: rotate(180deg);
    }
    @include respond-to('medium') {
        align-self: center;
    }
    &--order {
        min-width: 170px;
        margin-left: 0;
    }
}