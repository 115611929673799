@import "../variables";
@import "../mixins";
@import "../breakpoints";

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    background-color: $color-primary;
    color: #fff !important;
    border: none;
    border-radius: 30px;
    padding: 8px 15px;
    font-weight: 600;
    font-size: 16px;
    height: 4.6rem;
    outline: none;
    transition: all 0.1s;
    box-shadow: 4px 4px 10px rgba(232, 173, 22, 0.13);
    &--dark {
        background-color: $color-white;
        color: $color-primary !important;
    }
    &--right {
        margin-left: auto;
    }
    &--flex-center {
        align-self: center;
    }
    &--nav {
        background-color: $color-white;
        color: $color-primary !important;
        border-radius: 116px;
        padding: 8px 11px;
        height: auto;
        &--dark {
            background-color: $color-primary !important;
            color: $color-white !important;
        }
    }
    &--text {
        background-color: transparent;
        color: $color-grey-light !important;
        font-size: 12px;
        font-weight: 400;
        padding: 0;
        box-shadow: none;
    }
    &--normal {
        min-width: 200px;
    }
    &--order-active {
        background: #FFE2E2;
        color: $color-primary !important;
    }
    &:hover {
        transform: scale(1.02);
    }
    &:active {
        transform: scale(0.98);
    }
}