@import "../variables";
@import "../mixins";
@import "../breakpoints";

.input {
    position: relative;
    &--text-area {
        height: 100%;
    }
    &--text-area &__input {
        border-radius: 16px;
        height: 100%;
    }
    &__input {
        background-color: $color-white;
        padding: 2rem 1.2rem;
        border-radius: 999px;
        border: none;
        -webkit-appearance: none;
        -webkit-box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
        box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
        height: 4.6rem;
        width: 100%;
        font-size: 1.4rem;
        font-weight: 400;
        &::placeholder {
            color: #585858;
            font-size: 14px;
        }
        &:focus {
            outline: none;
        }
        &--shopping-card {
            box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
            border: 1px solid #000000;
            font-size: 2rem;
            font-weight: 300;
        }
    }
    textarea {
        height: auto;
        border-radius: 15px;
        resize: none;
    }
    &__label {
        position: absolute;
        top: 0;
        left: 2.6rem;
        transition: all 0.3s;
        transform: translate(0, -50%);
        font-size: 10px;
        visibility: visible;
        opacity: 1;
        padding: 2px 0.5rem;
        background-color: #FFF;
    }
    &__input:placeholder-shown+&__label {
        transform: translate(-0.8rem, 150%) scale(1.2);
        visibility: hidden;
        opacity: 0.3;
    }
    &[data-error='true']>&__error {
        visibility: visible;
        opacity: 1;
    }
    &__error {
        position: absolute;
        bottom: -5px;
        left: 2.4rem;
        font-size: 12px;
        color: red;
        transform: translate(0, 100%);
        transition: all 0.3s;
        visibility: hidden;
        opacity: 0.3;
        line-height: 18px;
    }
    &--shadow-top>&__input {
        box-shadow: 4px 0 9px rgba(0, 0, 0, 0.07);
    }
}
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}