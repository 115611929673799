@import "../variables";
@import "../mixins";
@import "../breakpoints";

.background {
    @include setBackground;
    &__top {
        position: absolute;
        top: 0;
        right: 0;
        &--menu {
            width: 91rem;
        }
    }
    &__black-pepper {
        position: absolute;
        right: 0;
        top: 0;
        &--full {
            position: absolute;
            left: 30%;
            top: 160px;
            z-index: -1;
        }
    }
    &__bottom {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    &__pizza {
        position: absolute;
        right: 0;
        top: 55%;
        z-index: 20;
        height: 80rem;
        transform: translate(0, -50%);
        &--contact {
            top: 75%;
            right: -40px;
        }
        &--error {
            bottom: 0;
        }
    }
    &__pizza-error {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    &__pepper {
        position: absolute;
        right: 38%;
        top: 15%;
        @include respond($phone) {
            display: none;
        }
    }
    &__basil {
        position: absolute;
        left: 0;
        bottom: 0;
        &--menu {
            bottom: 20rem;
        }
        &--contact {
            bottom: 30rem;
            left: 30%;
        }
        &--error {
            left: 50%;
            z-index: -1;
        }
    }
    &__tomatoes {
        position: absolute;
        left: 30%;
        bottom: 0;
        &--full {
            position: absolute;
            left: 5%;
            top: 150px;
        }
    }
    &__tomato {
        position: absolute;
        &--1 {
            right: 31%;
            bottom: 15rem;
        }
        &--2 {
            right: 45%;
            bottom: 7rem;
        }
    }
    &>* {
        &[index] {
            @include respond($phone) {
                display: none;
            }
        }
    }
}