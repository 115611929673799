@import "../variables";
@import "../mixins";
@import "../breakpoints";

.background-menu {
    @include setBackground;
    &__top {
        @include setElementInBackground('../assets/background-images-desktop/bckg-shape-top.svg', 48%, 100%, top, 56%) {
            right: 0;
            top: 0;
            z-index: 20;
            @include respondBckgItem(1220px, 77%, 100%);
            @include respondBckgItem(900px, 83%, 100%);
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__pizza {
        @include setElementInBackground('../assets/background-images-desktop/pizza.png', 30%, 90%, right, 30%) {
            right: 0;
            top: 0;
            z-index: 30;
            @include respondBckgItem(1220px, 48%, 100%);
            @include respondBckgItem(900px, 60%, 100%);
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__basil {
        @include setElementInBackground('../assets/background-images-desktop/basil-full.png', 400px, 400px, bottom left, 400px) {
            left: -150px;
            bottom: -10px;
            z-index: 30;
            @include respond-to('medium') {
                display: none;
            }
        }
    }
    &__basil-mobile {
        display: none;
        @include setElementInBackground('../assets/background-images-mobile/basil-mobile-menu.png', 243px, 243px, left, 243px) {
            left: 0;
            top: 36%;
            @include respond-to('medium') {
                display: block;
            }
        }
    }
    &__tomato {
        display: none;
        @include setElementInBackground('../assets/background-images-mobile/tomato.png', 155px, 155px, right, 155px) {
            right: 0;
            top: 68%;
            z-index: 30;
            @include respond-to('medium') {
                display: block;
            }
        }
    }
}