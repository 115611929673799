@import "../variables";
@import "../mixins";
@import "../breakpoints";

.hamburger {
    width: 40px;
    height: 30px;
    display: none;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    &--sidebar {
        position: absolute;
        justify-content: center;
        top: 20px;
        right: 20px;
    }
    &__stick {
        height: 4px;
        background: $color-primary;
        border-radius: 5px 5px 5px 5px;
        &--sidebar {
            background: $color-white;
        }
        &--sidebar-top {
            transform: translateY(50%) rotate(0);
            animation: stick-rotation .8s forwards;
        }
        &--sidebar-bottom {
            transform: translateY(-50%) rotate(0);
            animation: stick-rotation-reverse .8s forwards;
        }
    }
    &:hover {
        transform: scale(0.95);
    }
}