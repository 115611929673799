@import "../variables";
@import "../mixins";
@import "../breakpoints";

.work-section {
    @include setMarginTopSection;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 200px - 41px);
    &__offers-box {
        display: flex;
        flex-direction: column;
        margin-top: 6.8rem;
        height: 100%;
        width: 100%;
        @include respond($phone) {
            margin-top: 3rem;
        }
    }
    &__header {
        margin: 0;
    }
    &>* {
        &[pagination] {
            margin-bottom: 60px;
        }
    }
    @include respond-to('huge') {
        min-height: calc(100vh - 100px - 41px);
    }
    @include respond-to('medium') {
        min-height: calc(100vh - 100px);
    }
}