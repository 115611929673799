.load-container {
    width: 100%;
    // height: calc(100% - 80px);
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 9999;
    background-color: #fff;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}