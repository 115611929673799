@import "../variables";
@import "../mixins";
@import "../breakpoints";

.order-section {
    display: grid;
    grid-template-columns: minmax(200px, 220px) 1fr;
    width: 100%;
    column-gap: 15px;
    &__order-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        grid-column: 2 / span 1;
        @include respond-to('huge') {
            grid-column: 1 / -1;
        }
        margin-bottom: 150px;
    }
    &__side {
        grid-column: 1 / span 1;
        @include respond-to('huge') {
            grid-column: 1 / -1;
        }
    }
    &>*[pagination] {
        grid-column: 2 / span 1;
        grid-row-end: -1;
        justify-self: end;
        align-self: start;
    }
    @include respond-to('huge') {
        grid-template-columns: 1fr;
    }
    @include setMarginTopSection;
}

.order-section-page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}