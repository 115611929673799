@import "../variables";
@import "../mixins";
@import "../breakpoints";

.footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    //gap: 5%;
    width: 100%;
    padding: 15px 0 15px 0;
    @include respond-to('medium') {
        //display: none;
    }
    a {
        margin-right: 75px;
        @include respond-to('medium') {
            margin-right: 50px;
        }
    }
}