@import "../variables";
@import "../mixins";
@import "../breakpoints";

.summary-shopping-cart {
    width: 100%;
    min-width: 30.3rem;
    background: $color-white;
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
    border-radius: 14px;
    padding: 13px 21px;
    .counter {
        display: none;
    }
    &__item {
        display: flex;
        margin-top: 30px;
        position: relative;
        @media (max-width: 650px) {
            flex-wrap: wrap;
        }
        &>[img] {
            margin-right: 10px;
            height: 65px;
            width: 65px;
            @media (max-width: 650px) {
                margin-right: 100%;
            }
        }
        @include respond(500px) {
            align-items: flex-start;
        }
    }
    &__item-box {
        flex-grow: 1;
        display: flex;
        align-items: center;
        grid-template-columns: repeat(2, minmax(0, max-content)) 1fr repeat(2, minmax(0, max-content));
        &>* {
            &[name] {
                width: 20%;
                @media (max-width: 650px) {
                    width: 50%;
                    margin-top: 10px;
                }
            }
            &[size] {
                width: 55%;
                @media (max-width: 1500px) {
                    width: 50%;
                }
                @media (max-width: 650px) {
                    width: 50%;
                    text-align: right;
                    margin-top: 10px;
                }
            }
            &[counter] {
                width: 15%;
                @media (max-width: 650px) {
                    width: 50%;
                    margin-top: 10px;
                }
            }
            &[price] {
                justify-self: end;
                grid-column: 5/ span 1;
                @media (max-width: 650px) {
                    width: 50%;
                    text-align: right;
                    margin-top: 10px;
                }
            }
            @include respond(500px) {
                &[name] {
                    grid-column: 1/ span 1;
                    grid-row: 1/ span 1;
                }
                &[size] {
                    grid-column: 1/ span 1;
                    grid-row: 2/ span 1;
                    justify-self: start;
                }
                &[counter] {
                    grid-column: 1/ span 1;
                    grid-row: 3/ span 1;
                }
                &[price] {
                    justify-self: end;
                    grid-column: 5/ span 1;
                }
            }
        }
        @include respond(500px) {
            flex-wrap: wrap;
        }
    }
    &__proposed-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &__right-box {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-grow: 1;
            align-self: flex-start;
            gap: 20px;
            @include respond(500px) {
                align-self: flex-end;
                margin-top: 10px;
            }
        }
        &__description-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &__left-box {
            display: flex;
            &>[img] {
                margin-right: 10px;
            }
        }
        @include respond(500px) {
            flex-direction: column;
        }
    }
    &__items {
        &>:first-child {
            margin-top: 1.4rem;
        }
        &>:not(:last-child) {
            margin-bottom: 3rem;
        }
    }
    &__break-line {
        width: 98%;
        height: 1px;
        margin: 30px 0;
        display: block;
        background-color: #d6d6d6;
    }
    .wppizza-cart-buttons, .wppizza-ordernow, .wppizza-orders-pickup-choice, .wppizza-delivery-note, .wppizza-personal-details, .wppizza-order-wrap {
        //display: none !important;
    }
    .wppizza-order-wrap {
        //display: none !important;
        .wppizza-fieldset {
            border: 0;
            margin: 0;
        }
        legend, .wppizza-table-header-group, .wppizza-personal-details, .wppizza-payment-methods, .wppizza-ordernow, .wppizza-delivery-note, .wppizza-orders-pickup-choice, .wppizza-total-items, .wppizza-delivery, .wppizza-total, .wppizza-discount, .wppizza-wppizza_cad_coupon, .wppizza-pickup-note {
            display: none !important;
        }
        .wppizza-item-article {
            display: flex;
            align-items: flex-start;
            margin-left: 30px;
            width: 70%;
            @include respond(900px) {
                margin-left: 0;
                width: 100%;
            }
        }
        .wppizza-item-title, .wppizza-item-total {
            padding-right: 20px;
            width: 70%;
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            justify-content: flex-start;
            align-items: flex-start;
            span {
                width: 100%;
                &:first-child {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 30px;
                    @media (max-width: 1440px) {
                        font-size: 1.6rem;
                    }
                }
                font-size: 14px;
                line-height: 22px
            }
        }
        .wppizza-item-size {
            font-size: 1.8rem;
            margin-top: 10px;
            width: 30%;
            @media (max-width: 1440px) {
                font-size: 1.6rem;
            }
            @include respond(900px) {
                width: 50%;
            }
        }
        .wppizza-item-price {
            display: none;
        }
        .wppizza-item-total {
            font-size: 1.8rem;
            font-weight: 500;
            text-align: left !important;
            width: 20%;
            line-height: 36px;
            @media (max-width: 1440px) {
                font-size: 1.6rem;
            }
            @include respond(900px) {
                width: 30%;
            }
        }
        .wppizza-item-delete {
            @include respond(900px) {
                width: 60% !important;
                text-align: right;
            }
            img {
                width: 35px;
                object-fit: contain;
                cursor: pointer;
            }
        }
        .wppizza-item-row {
            border-top: 1px solid #cecece;
            display: flex;
            margin-top: 20px;
            padding: 7px 0px;
            @include respond(900px) {
                flex-wrap: wrap;
            }
            &:first-child {
                margin-top: 0;
            }
        }
        .wppizza-order-itemised {
            td {
                padding: 0 !important;
            }
            .wppizza-item-row {
                > td {
                    border: 0 !important;
                }
            }
        }
        .wppizza-item-delete {
            margin-right: 20px;
            margin-top: 10px;
        }
        .wppizza-order-summary {
            margin-top: 25px;
            width: 100%;
        }
    }
    .wppizza-order-summary {
        //display: none;
    }
    .wppizza-item-update {
        width: 66px !important;
        padding: 0;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 10px;
        @include respond(900px) {
            width: 62px !important;
        }
        .ui-spinner {
            position: relative;
            width: 100% !important;
            border: 0;
            &-down {
                position: absolute;
                left: 0;
                top: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFE2E2;
                height: 16px !important;
                width: 16px !important;
                border-radius: 50%;
                cursor: pointer;
                .ui-button-icon {
                    background-image: none;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 5px;
                        top: 7px;
                        right: 5px;
                        height: 1px;
                        color: black;
                        font-size: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 1;
                        border-top: 1px solid;
                    }
                }
            }
            &-up {
                position: absolute;
                right: 0;
                top: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFE2E2;
                height: 16px !important;
                width: 16px !important;
                border-radius: 50%;
                cursor: pointer;
                .ui-button-icon {
                    background-image: none;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 4px;
                        top: 7px;
                        right: 5px;
                        height: 1px;
                        color: black;
                        font-size: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 1;
                        border-bottom: 1px solid #fe3838;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        left: 7px;
                        top: 4px;
                        bottom: 5px;
                        height: 7px;
                        color: black;
                        font-size: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 1;
                        border-right: 1px solid #fe3838;
                    }
                }
            }
            &-input {
                padding: 0 !important;
                text-align: center;
                &:focus {
                    border: 0;
                    outline: 0;
                }
            }
        }
    }
    .removeFromCart {
        position: absolute;
        right: 0;
        top: 21px;
        font-size: 20px;
        cursor: pointer;
        &:hover {
            opacity: .7;
        }
    }
    #wppizza_cad_coupons {
        background-color: $color-white;
        padding: 2rem 1.2rem;
        border-radius: 999px;
        border: none;
        -webkit-appearance: none;
        -webkit-box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
        box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
        height: 4.6rem;
        width: 100% !important;
        max-width: unset !important;
        font-size: 1.4rem;
        font-weight: 400;
        &::placeholder {
            color: #585858;
            font-size: 14px;
        }
        &:focus {
            outline: none;
        }
    }
    #wppizza_cad_toggle {
        color: $color-primary;
    }
    #wppizza_cad_coupons_wrap {
        position: relative;
        width: 50%;
        margin-top: 10px;
        @include respond(900px) {
            width: 100%;
        }
    }
    #wppizza_cad_coupons-btn {
        height: 4.6rem !important;
        position: absolute;
        right: 0;
        top: 0;
        margin: 0 !important;
        padding: 14px 15px;
        @include respond(900px) {
            top: 4.6rem;
        }
    }
    #wppizza_cad_coupons_remove {
        display: none !important;
    }
    .wppizza-login {
        overflow: unset;
        display: none;
    }
    .imageSummary {
        width: 150px;
        min-width: 150px;
        height: 120px;
        object-fit: cover;
        border-radius: 25px;
        margin-right: 25px;
    }
}
#wppizza_cad_coupons-results {
    display: none;
}

#wppizza_cad_coupons_wrap {
    display: none;
    position: relative;
    width: 50%;
    margin-top: 10px;
    margin-bottom: 5px;
}

.d-block {
    display: block !important;
}