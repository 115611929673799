@keyframes stick-rotation {
  0% {
    transform: translateY(50%) rotate(0);
  }
  100% {
    transform: translateY(50%) rotate(315deg);
  }
}

@keyframes stick-rotation-reverse {
  0% {
    transform: translateY(-50%) rotate(0);
  }
  100% {
    transform: translateY(-50%) rotate(-315deg);
  }
}

@keyframes fadeInLeft {
  0% {
    transform: translateX(-260px);
  }
  100% {
    transform: translateX(0);
  }
}