//COLORS
$color-primary: #fe3838;
$color-secondary:#e5e5e5;
$color-grey-light: #585858;
$color-grey-light-2:#979797;
$color-white: #fff;
$color-black: #000;
// TRANSITIONS
$base-transition: all 0.3s;
// BREAKPOINTS
$phone:755px;
$navbar-mobile:1070px;