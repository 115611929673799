@import './_variables';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body {
  font-family: 'Poppins', sans-serif;
}

a {
  &,
  &:visited,
  &:active {
    text-decoration: none;
    color: inherit;
    transition: $base-transition;
    cursor: pointer;
  }
}

.text {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
  &__w300 {
    font-weight: 300;
  }
  &__w400 {
    font-weight: 400;
  }
  &__w500 {
    font-weight: 500;
  }
  &__w600 {
    font-weight: 600;
  }
  &__w700 {
    font-weight: 700;
  }
  &__small {
    font-size: 1.4rem;
    &--2 {
      font-size: 1.6rem;
    }
  }
  &__medium {
    font-size: 1.8rem;
    &--2 {
      font-size: 2.4rem;
    }
  }
  &__large {
    font-size: 3.6rem;
    &--2 {
      font-size: 4.8rem;
    }
  }
  &__lh-20 {
    line-height: 20px;
  }
  &__white {
    color: $color-white !important;
  }
  &__black {
    color: $color-black;
  }
  &__primary {
    color: $color-primary;
  }
}