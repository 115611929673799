@import "../variables";
@import "../mixins";
@import "../breakpoints";

    .user-panel {
        display: grid;
        grid-template-columns: minmax(200px, 220px) repeat(2, minmax(0, 465px)) 1fr;
        grid-column-gap: 40px;
        grid-template-rows: auto 1fr;
        min-height: calc(100vh - 200px - 40px);
        margin-bottom: 60px;
        &__side {
            grid-column: 1/ span 1;
            @include respond-to('huge') {
                grid-column: 1 / -1;
            }
        }
        &__discount-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            min-width: 157px;
            height: 146px;
            box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
            border-radius: 14px;
            background: $color-white;
            padding: 7px 10px;
            margin-top: 20px;
            &>* {
                &[txt] {
                    text-align: center;
                }
            }
            @include respond-to('huge') {
                margin-bottom: 30px;
            }
        }
        &__vat-form {
            align-self: end;
            margin-bottom: 126px;
        }
        &__form-wrapper {
            width: 70%;
        }
        &__ul {
            display: flex;
            flex-direction: column;
            list-style-type: none;
            gap: 11px;
            &>* {
                cursor: pointer;
            }
            @include respond-to('huge') {
                flex-direction: row;
                justify-content: space-around;
            }
        }
        &>* {
            &[form-left] {
                grid-column: 2 / span 1;
                @include respond-to('huge') {
                    grid-column: 1 / span 1;
                }
                @include respond-to('medium') {
                    grid-column: 1 / -1;
                }
            }
            &[form-right] {
                grid-column: 3 / span 1;
                @include respond-to('huge') {
                    grid-column: 2 / span 1;
                }
                @include respond-to('medium') {
                    grid-column: 1 / -1;
                }
            }
        }
        @include respond-to('huge') {
            grid-template-columns: 1fr 1fr;
        }
        @include respond-to('medium') {
            &>* {
                &:not(:first-child):not(:last-child) {
                    margin-bottom: 100px;
                }
                &:first-child {
                    margin-bottom: 30px;
                }
            }
        }
        @include setMarginTopSection;
    }