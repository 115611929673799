@import "../variables";
@import "../mixins";
@import "../breakpoints";

.pagination {
    display: flex;
    align-self: flex-end;
    margin-top: 21px;
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-left: 8px;
        background-color: $color-primary;
        cursor: pointer;
        color: $color-white;
    }
    &__current-page-no {
        margin-right: 5px;
    }
    &__last-page-no {
        margin-left: 5px;
    }
    @include respond-to('medium') {
        display: none;
    }
}