@import "../variables";
@import "../mixins";
@import "../breakpoints";

        .background-about {
            @include setBackground;
            &__black-pepper {
                @include setElementInBackground('../assets/background-images-desktop/black-pepper-full.png', 55%, 55%, center, 55%) {
                    top: 40%;
                    left: 20%;
                    z-index: -1;
                    @include respond-to('large') {
                        left: 0;
                    }
                    @include respond-to('medium') {
                        display: none;
                    }
                }
            }
            &__pizza {
                @include setElementInBackground('../assets/background-images-desktop/pizza.png', 35%, 100%, right, 35%) {
                    right: 0;
                    top: 0;
                    z-index: 30;
                    @include respondBckgItem(1220px, 48%, 100%);
                    @include respondBckgItem(900px, 60%, 100%);
                }
                @include respond($phone) {
                    display: none;
                }
            }
            &__tomatoes {
                @include setElementInBackground('../assets/background-images-desktop/tomatoes-full.png', 75%, 75%, left, 75%) {
                    left: 0;
                    top: 25%;
                    z-index: 25;
                    @include respondBckgItem(900px, 60%, 100%);
                }
              @media (max-width: 1440px) {
                top: 33%;
              }
                @include respond-to('medium') {
                    display: none;
                }
            }
            &__pizza-mobile {
                @include setElementInBackground('../assets/background-images-mobile/pizza-about-mobile.png', 155px, 155px, right, 155px) {
                    display: none;
                    right: 0;
                    top: 120px;
                    @include respond-to('medium') {
                        display: block;
                    }
                }
            }
        }