@import "../variables";
@import "../mixins";
@import "../breakpoints";

.form-grid {
    display: grid;
    width: 100%;
    // grid-template-columns: repeat(2, minmax(250px, 400px));
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    &--work-apply {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, auto);
        align-items: center;
        justify-items: center;
        @include respond(1000px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include respond($phone) {
            grid-template-columns: 100%;
            margin-top: 25px;
        }
        .form-group {
            &.error {
                .input__input {
                    border: 1px solid red;
                }
            }
        }
        .input__error {
            visibility: unset;
            opacity: 1;
            bottom: 26px;
        }
        .check-box {
            flex-wrap: wrap;
        }
        .checkboxError {
            display: block;
            font-size: 12px;
            margin-top: 5px;
            color: red;
        }
        .fileError {
            bottom: unset;
            top: 7px !important;
            left: 550px;
            text-align: right;
            width: 150px;
            @include respond($phone) {
                right: unset;
                left: 0;
                top: 40px !important;
            }
        }
    }
    &--order-summary {
        grid-template-columns: repeat(2, 1fr);
        max-width: 470px;
        margin: 30px 0;
    }
    &--contact {
        max-width: calc(2 * 400px + 86px) !important;
        column-gap: 60px;
        &__text-area {
            grid-row: 2/5;
        }
        @include respond($phone) {
            grid-template-columns: 1fr;
            row-gap: 25px;
        }
        &>* {
            &[btn] {
                @include respond($phone) {
                    justify-self: center;
                    grid-column: 1/-1;
                }
            }
            &[textarea] {
                @include respond($phone) {
                    min-height: 140px;
                    grid-row: 5 / span 1 !important;
                }
            }
        }
        .form-group {
            &.error {
                .input__input {
                    border: 1px solid red;
                }
            }
        }
        .input__error {
            visibility: unset;
            opacity: 1;
        }
        .js-input {
            margin-top: 30px;
            &.error {
                .input__input {
                    //border: 1px solid red;
                }
            }
        }
        .btn {
            margin-top: 25px;
        }
        .checkboxError {
            display: block;
            font-size: 12px;
            margin-top: 5px;
            color: red;
        }
        .errorRecaptcha {
            position: absolute;
        }
    }
    &--user-panel {
        gap: 2rem 1rem;
        @include respond-to('medium') {
            grid-template-columns: 1fr;
        }
    }
    &--password {
        &>*[btn] {
            grid-column: 1/-1;
            justify-self: end;
        }
        &>*[check] {
            margin-left: 30px;
            grid-column: 3/4;
            @include respond-to('medium') {
                grid-column: 1/-1;
            }
        }
        @include respond-to('medium') {
            row-gap: 20px;
        }
    }
    &>* {
        &[btn] {
            justify-self: end;
            @include respond($phone) {
                justify-self: center;
            }
        }
        &[btn-index] {
            margin-top: 90px;
        }
        &[btn-user-panel] {
            margin-top: 60px;
            max-width: 210px;
            @include respond-to('medium') {
                justify-self: center;
            }
        }
    }
    &>* {
        &[col-1-2] {
            grid-column: 1/2;
        }
        &[col-full] {
            grid-column: 1/-1;
        }
        &[col-2-3] {
            grid-column: 2/3;
        }
        &[col-2-2] {
            grid-column: 2/3;
        }
        &[row-2-5] {
            grid-row: 2/5;
        }
        @include respond($phone) {
            &[col-2-3],
            &[col-2-2] {
                grid-column: 1/2;
            }
        }
    }
    .recaptcha {
        margin-top: 15px;
    }
}