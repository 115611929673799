@import "./_mixins";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

/* Default 16px on browsers, 10px */

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  box-sizing: border-box;
  background-color: #fbfbfb;
}

.main-container {
  max-width: calc(1920px - 240px + 2 * 16px);
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
  overflow: hidden;
  min-width: 250px;
  @include respond(900px) {
    max-width: calc(1920px - 240px + 2 * 8px);
    padding: 0 8px;
  }
  &.intro {
    max-width: unset;
    padding: 0;
  }
}

.d-none {
  display: none !important;
}

.d-hidden {
  visibility: hidden;
}

.premises {
  display: none;
}

.price-offer-before {
  display: block;
  font-size: 18px;
  text-align: left;
  color: #a2a2a2;
  text-decoration: line-through;
}
