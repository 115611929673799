@mixin absPostionCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin respond($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

@mixin respondBckg($br) {
  @media (max-width: $br) {
    @content;
  }
}

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);
  @media #{$raw-query} {
    @content;
  }
}

@mixin setBackground {
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  &__container {
    position: relative;
    width: 100vw;
    height: 100vh;
  }
}

@mixin setElementInBackground($url, $width, $height, $bckg-positon, $width-laptop) {
  position: absolute;
  height: $height;
  width: $width;
  background-position: $bckg-positon;
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: contain;
  @content;
  @media (max-width: 1440px) {
    width: $width-laptop;
  }
}

@mixin setMarginTopSection {
  margin-top: 150px;
  @include respond-to('huge') {
    margin-top: 110px;
  }
}

@mixin respondBckgItem($brpoint, $width, $height) {
  @include respondBckg($brpoint) {
    width: $width;
    height: $height;
    @content;
  }
}