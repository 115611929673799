@import "../variables";
@import "../mixins";
@import "../breakpoints";

.menu-section {
  text-align: center;
  margin: 35px auto 200px auto;
  display: grid;
  gap: 4rem 5rem;
  grid-template-columns: 1fr minmax(315px, 400px);
  justify-content: space-between;
  -webkit-overflow-scrolling: touch;
  @media (max-width: 1440px) {
    margin: 25px auto 200px auto;
  }
  @media (max-width: 1400px) {
    margin: 7rem auto 200px auto;
  }
  @include respond(900px) {
    margin-top: 11rem;
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: 1fr;
    order: 1;
    gap: 0;
    margin-bottom: 0;
  }
  &.cartSection {
    @include respond($phone) {
      margin-top: 6rem;
    }
  }
  &__header {
    grid-column: 1/-1;
    @media (max-width: 768px) {
      & > h1 {
        display: none;
      }
    }
    h1 {
      margin-bottom: 25px;
      @media (max-width: 1440px) {
        margin-bottom: 20px;
      }
    }
  }
  &__menu {
    grid-column: 1/-1;
  }
  &__products {
    position: relative;
    grid-column: 1/2;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    //row-gap: 3.5rem;
    justify-content: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(200px, 366px));
    &.main {
      max-width: 80%;
      margin-top: 30px;
    }
    @include respond(900px) {
      order: 2;
      margin-top: 15px;
    }
    &.home {
      @include respond(900px) {
        order: unset;
      }
    }
    .emptyProduct {
      display: none;
      &.active {
        display: flex;
      }
    }
    .wppizza-article-default {
      display: none;
      flex-wrap: wrap;
      margin: 0 2% 30px 0 !important;
      padding: 0 !important;
      position: relative;
      width: 100%;
      background-color: $color-white;
      border-radius: 14px;
      box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
      width: 31%;
      &:nth-child(3n + 3) {
        margin-right: 0 !important;
      }
      @media (max-width: 1420px) {
        //margin: 0 2% 0 0 !important;
      }
      @media (max-width: 1050px) {
        width: 49%;
      }
      @media (max-width: 650px) {
        width: 100%;
        margin: 0 0 15px 0 !important;
        -webkit-appearance: none;
        -webkit-box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.09);
        box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.09);
      }
      & > div:last-child {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        @media (max-width: 768px) {
          width: 59%;
          display: flex;
          flex-wrap: wrap;
        }
      }
      .wppizza-article-image {
        width: 100%;
        @media (max-width: 768px) {
          width: 40%;
        }
        .wppizza-article-image-thumb {
          margin: 0 !important;
          padding: 0;
          width: 100%;
          height: 290px;
          object-fit: cover;
          border-radius: 14px 14px 0 0;
          @media (max-width: 768px) {
            border-radius: 14px 0px 0px 14px;
            height: 100%;
            min-height: 165px;
          }
        }
        .drink {
          object-fit: contain;
        }
      }
      .wppizza-article-h2 {
        width: 100%;
        font-size: 2.4rem;
        text-align: left;
        line-height: 38px;
        font-weight: 500;
        padding: 0 15px;
        height: 72px;
        @include respond($phone) {
          font-size: 1.4rem;
          height: 28px;
          line-height: 28px;
        }
      }
      .wppizza-article-content {
        text-align: left;
        line-height: 20px;
        font-size: 1.4rem;
        font-weight: 300;
        padding: 0 15px;
        height: 60px;
        display: flex;
        flex-wrap: wrap;
        align-content: baseline;
        width: 100%;
        @media (max-width: 1600px) {
          height: 80px;
        }
        @media (max-width: 1420px) {
          height: 80px;
        }
        @media (max-width: 900px) {
          height: auto;
        }
        @include respond($phone) {
          font-size: 1.2rem;
          line-height: 18px;
        }
        em {
          display: none;
        }
        div {
          margin-right: 5px;
          height: 20px;
          @include respond($phone) {
            height: 18px;
          }
        }
      }
      .wppizza-article-sizes {
        display: none;
        padding: 0 15px;
      }
      .menu-item__size-menu {
        margin: 5px 15px;
        @include respond($phone) {
          width: 90%;
          height: 32px;
        }
      }
      .menu-item__price {
        padding: 10px 15px 15px 15px;
        @include respond($phone) {
          padding: 0 15px 5px 15px;
        }
      }
    }
    .wppizza_menu-lokal-grabiszynska {
      display: flex !important;
    }
    .wppizza-article-makarony {
      .wppizza-article-content {
        height: 60px;
        @media (max-width: 1440px) {
          height: 100px;
        }
      }
    }
    .wppizza-ingredients {
      display: none;
    }
  }
  &__cart {
    grid-column: 2/3;
    @media (max-width: 650px) {
      display: none;
    }
    &.mobile {
      @media (max-width: 650px) {
        display: block;
      }
    }
    &.disable {
      @media (max-width: 650px) {
        display: none;
      }
    }
    @include respond(900px) {
      //display: none;
      width: 100%;
      order: 1;
    }
  }
  .cartBack {
    position: relative;
    margin: 15px 0;
    padding-left: 52px;

    .arrow {
      position: absolute;
      top: 3px;
      left: 20px;
      border: solid $color-primary;
      border-width: 0 4px 4px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }
}
