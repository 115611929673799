@import "../variables";
@import "../mixins";
@import "../breakpoints";

.customize-pizza {
  @include setMarginTopSection;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  &__customize-box {
    margin-bottom: 30px;
    @include respond($phone) {
      margin-bottom: 20px;
    }
    .wppizza-article-default {
      display: none;
    }
    .mrg-bottom-40 {
      @include respond($phone) {
        margin-bottom: 1rem !important;
      }
    }
  }
  &__left-side {
    margin-right: 30px;
    max-width: 650px;
    @include respond($phone) {
      margin-right: 0;
    }
    & > [current-ingrediens] {
      @include respond($phone) {
        //display: none;
      }
    }
  }
  &__right-side {
    width: 700px;
    @media (max-width: 1440px) {
      width: 600px;
    }
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__box-header {
    margin-bottom: 15px;
    @include respond($phone) {
      margin-bottom: 10px;
    }
    &--1 {
      display: block;
      @include respond-to("huge") {
        display: none;
        font-size: 12px;
        font-weight: 400;
      }
    }
    &--2 {
      display: none;
      @include respond-to("huge") {
        display: block;
      }
    }
    @include respond-to("medium") {
      font-size: 12px;
      font-weight: 600;
    }
  }
  &__box-desc {
    margin-bottom: 30px;
  }
  &__img {
    width: 100%;
    height: auto;
    @include respond-to("medium") {
      //display: none;
    }
  }
  &__img-mobile {
    width: 100%;
    height: auto;
    display: none;
  }
  &__header {
    margin-top: 0;
    margin-bottom: 2rem;
    @include respond($phone) {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 2rem;
    }
    &-desc {
      margin-bottom: 4rem;
      @include respond($phone) {
        font-size: 12px;
      }
    }
  }
  &__summary {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 35px 0 0 0;
    @include respond-to("medium") {
      width: 100%;
      position: fixed;
      background: $color-white;
      padding: 10px;
      left: 0;
      bottom: 0;
      z-index: 10;
      -webkit-appearance: none;
      -webkit-box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.09);
      box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.09);
      justify-content: space-between;
      align-items: center;
    }
    &__price {
      @include respond-to("medium") {
        //display: none;
      }
    }
    .btn {
      padding: 8px 25px;
      height: 4rem;
    }
  }
  &__result-mobile {
    display: none;
    width: 100%;
    background: $color-white;
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
    border-radius: 14px;
    &__description-box {
      display: flex;
      flex-direction: column;
      padding: 8px 2px 8px 2px;
      margin-bottom: 36px;
    }
    &__header {
      margin-bottom: 26px;
    }
    @include respond-to("medium") {
      //display: block;
    }
  }
  @include respond-to("huge") {
    flex-direction: column;
    margin-top: 70px;
  }
  .wppizza-article-default {
    display: none;
    width: 638px;
  }
  .wppizza-imulti {
    display: none !important;
  }
  .menu-item__size-menu {
    margin-bottom: 30px;
    @include respond($phone) {
      margin-bottom: 15px;
      height: 22px;
    }
  }
  .menu-item__size {
    @include respond($phone) {
      font-size: 12px;
      line-height: 12px;
      height: 22px;
    }
  }
  .customizeContent {
    em {
      display: none;
    }
  }
  .addedIngredients {
    display: none;
    &.active {
      display: flex;
    }
  }
  .wppizza-categories {
    margin: 0;
    li {
      margin-right: 6px;
      margin-bottom: 25px;
      @include respond($phone) {
        margin-bottom: 15px;
      }
      &:nth-child(3n + 3) {
        @media (max-width: 650px) {
          margin-right: 6px;
        }
      }
      a {
        @include respond($phone) {
          font-size: 12px;
          padding: 0 0.8rem;
        }
      }
    }
  }
  .customizeBox {
    width: 100%;
    div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 15px;
      @include respond($phone) {
        margin-top: 3px;
        margin-bottom: 2px;
      }
      span {
        @include respond($phone) {
          font-size: 12px;
        }
      }
    }
  }
  .twopizza,
  .twoSauce {
    .species-menu {
      width: 625px;
      flex-direction: column;
      @include respond($phone) {
        width: 100%;
      }
      &__item {
        width: 100%;
        margin-bottom: 15px;
        justify-content: flex-start;
        span {
          display: flex;
          font-size: 12px;
          width: 100%;
          //   margin-left: 10px;
          @include respond($phone) {
            font-size: 7px;
            width: 77%;
          }
          div {
            margin-right: 4px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .species-menu-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      .species-menu__item {
        margin-bottom: 0;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        min-height: 3.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        justify-content: center;
      }
    }
  }
  .twoSauce {
    .species-menu {
      &__item {
        width: 45%;
        margin-right: 5%;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
  .pizza-modal {
    .modal__content {
      min-height: 215px;
    }
  }
}
