@import "../variables";
@import "../mixins";
@import "../breakpoints";

.close-icon {
    width: 15px;
    height: 15px;
    position: absolute;
    // right: 10px;
    // top: 15px;
    cursor: pointer;
    transition: all .2s;
    right: 15px;
    &__stick-1,
    &__stick-2 {
        position: absolute;
        width: 1px;
        height: 100%;
        background: $color-grey-light-2;
    }
    &__stick-1 {
        transform: rotate(45deg);
    }
    &__stick-2 {
        transform: rotate(-45deg);
    }
    &:hover>&__stick-1,
    &:hover>&__stick-2 {
        background: $color-black;
    }
    &:hover {
        transform: scale(1.02);
    }
    &:active {
        transform: scale(0.98);
    }
}