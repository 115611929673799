@import "../../themes/mixins";
@import "../../themes/breakpoints";
@import "../../themes/variables";

.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  @include respond-to('huge'){
    background-color: #FBFBFB;
    min-height: 62px;
  }
  &__layout {
    //@extend .main-container;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    //margin-top: 1.5rem;
    overflow: visible;
    max-width: calc(1920px - 240px + 2 * 16px);
    width: 100%;
    padding: 0 16px;
    margin: 1.5rem auto 0 auto;
    @include respond-to('huge') {
      flex-direction: row-reverse;
      &>[hamburger] {
        display: flex;
      }
    }
  }
  &__logo {
    display: flex;
    height: 100%;
    margin-left: -10px;
    align-items: center;
    & img {
      height: inherit;
      width: 211px;
    }
    @include respond-to('huge') {
      display: none;
    }
    &--mobile {
      display: none;
      @include respond-to('huge') {
        display: flex;
      }
      &>img {
        width: 90px !important;
        height: auto !important;

      }
    }
  }
  &__right-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 0;
    flex-basis: 145px;
    &--login {
      @include respond-to('max') {
        flex-basis: 150px;
      }
    }
    &.is-login {
      flex-basis: 180px;
    }
  }
  &__left-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: auto;
    flex-basis: 600px;
    @media (max-width: 1420px) {
      flex-basis: 550px;
    }
    &--login {
      @include respond-to('max') {
        flex-basis: 400px;
      }
    }
  }
  &__menu {
    flex-basis: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1420px) {
      flex-basis: 920px;
    }
    &--login {
      flex-basis: 1000px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-basis: 800px;
      @include respond-to('max') {
        flex-basis: 650px;
      }
      &--scroll {
        align-items: center;
      }
    }
    @include respond-to('huge') {
      display: none;
    }
  }
  &__icon {
    &--basket {
      display: flex;
      flex-basis: 100px;
      justify-content: center;
      cursor: pointer;
      position: relative;
      &--login {
        @include respond-to('max') {
          flex-basis: auto;
        }
      }
      &--counter {
        position: absolute;
        left: 15px;
        top: 20px;
        border-radius: 8.5px;
        background-color: $color-white;
        display: flex;
        padding: 0 10px;
        white-space: nowrap;
      }
    }
  }
  &__background {
    width: 100%;
    height: 6rem;
    position: absolute;
    top: 0;
    left: 0;
    background: $color-white;
    box-shadow: 0 1px 5px 1px #8F8F8F;
    transition: all .4s;
    transform: scaleX(0);
    z-index: -1;
    @media (max-width: 900px) {
      box-shadow: none;
      border-bottom: 1px solid $color-primary;
    }
  }
  &__link {
    &.is-active-white {
      color: $color-white;
    }
    &.is-active {
      color: $color-primary;
    }
  }
  .dropdown {
    padding-top: 7px;
    margin-left: 5px;
    @include respond-to('medium') {
      padding-top: 0;
    }
    &.active {
      .arrdow {
        transform: rotate(
                        180deg
        );
      }
    }
  }
  .myAddress {
    @include respond-to('medium') {
      display: none;
    }
    span {
      display: none;
    }
  }
  &--scroll &__background {
    transform: scale(1);
  }
  &--scroll>&__layout {
    height: 6rem;
    margin-top: 0;
    align-items: center;
    & img {
      height: 80%;
      width: auto;
    }
    & .btn {
      background: $color-primary;
      color: $color-white !important;
    }
    & .dropdown {
      padding-top: 0;
    }
  }
  .wppizza-cart, .wppizza-orders-pickup-choice, .wppizza-opening-hours {
    display: none !important;
  }
  .price-modal {
    .modal__content {
      min-height: 250px;
    }
  }
  .temp-modal {
    .modal__content {
      min-height: 225px;
    }
  }
}

.basket-dropdown {
  position: relative;
  z-index: 999999999;
  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 254px;
    height: 217px;
    border-radius: 0 15px 15px 15px;
    background: $color-white;
    padding: 10px 15px;
    &>* {
      &[btn] {
        align-self: stretch;
      }
    }
  }
  &__icon {}
  &__summary {
    align-self: stretch;
    display: flex;
    justify-content: space-between;
  }
  &__icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 60px;
    border-radius: 14px 14px 0 0;
    transform: translateY(2px);
    background-color: $color-white;
  }
  &__break {
    align-self: stretch;
    height: 1px;
    background: $color-grey-light-2;
  }
  &__item {
    position: relative;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    &>* {
      &[close] {
        top: 0;
        left: 0;
      }
    }
  }
}

.cartMobile {
  position: fixed;
  top: 700px;
  left: 10px;
  background-color: #FE3838;
  padding: 5px;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  display: none;
  justify-content: center;
  align-items: center;
  @include respond(768px) {
    display: flex;
  }
  @media only screen and (max-height: 800px) {
    top: 650px;
  }
  @media only screen and (max-height: 750px) {
    top: 600px;
  }
  @media only screen and (max-height: 700px) {
    top: 550px;
  }
  @media only screen and (max-height: 650px) {
    top: 500px;
  }
  @media only screen and (max-height: 600px) {
    top: 450px;
  }
  @media only screen and (max-height: 550px) {
    top: 400px;
  }
  svg {
    width: 30px;
  }
  span {
    position: fixed;
    top: 710px;
    left: 75px;
    background-color: #FE3838;
    color: #FFF;
    border-radius: 10px;
    height: 30px;
    padding: 0 10px;
    line-height: 30px;
    @media only screen and (max-height: 800px) {
      top: 660px;
    }
    @media only screen and (max-height: 750px) {
      top: 610px;
    }
    @media only screen and (max-height: 700px) {
      top: 560px;
    }
    @media only screen and (max-height: 650px) {
      top: 510px;
    }
    @media only screen and (max-height: 600px) {
      top: 460px;
    }
    @media only screen and (max-height: 550px) {
      top: 410px;
    }
  }
  .number {
    position: fixed;
    top: 732px;
    left: 40px;
    height: 25px;
    width: 25px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-height: 800px) {
      top: 682px;
    }
    @media only screen and (max-height: 750px) {
      top: 632px;
    }
    @media only screen and (max-height: 700px) {
      top: 582px;
    }
    @media only screen and (max-height: 650px) {
      top: 532px;
    }
    @media only screen and (max-height: 600px) {
      top: 482px;
    }
    @media only screen and (max-height: 550px) {
      top: 432px;
    }
  }
}