@import "../variables";
@import "../mixins";
@import "../breakpoints";

.work-apply-section {
    margin-top: 151px;
    &__header {
        margin-bottom: 63px;
        margin-top: 0;
        @include respond($phone) {
            margin-bottom: 30px;
        }
    }
    &__description-form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem;
        margin-top: 22px;
        width: 100%;
        min-height: 700px;
        background: $color-white;
        box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.02);
        margin-bottom: 100px;
        @include respond($phone) {
            margin-top: 0;
        }
    }
    &__break-line {
        width: 98%;
        height: 1px;
        background-color: #979797;
        margin: 0 auto;
        @include respond($phone) {
            display: none;
        }
    }
    &__input-group {
        justify-self: start;
        display: flex;
        flex-direction: column;
        width: 80%;
        @include respond($phone) {
            justify-self: center;
            width: 100%;
        }
        input {
            margin-bottom: 28px;
        }
    }
    &__terms-check-box {
        align-items: flex-start;
        margin-bottom: 25px;
    }
    &__cv-terms-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        position: relative;
        @include respond(1000px) {
            grid-column: -2/-1;
        }
        &.error {
            .input__error {
                visibility: unset;
                opacity: 1;
                top: 32px;
                bottom: unset;
            }
        }
    }
    &__btn {
        justify-self: end;
        min-width: 150px;
        @include respond(1000px) {
            grid-column: 1 / -1;
            justify-self: center;
        }
        @include respond($phone) {
            grid-row: 3/4;
        }
    }
    &__cv-input {
        visibility: hidden;
    }
    &__cv-label {
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
    }
    &__add-cv-text {
        cursor: pointer;
    }
    &__icon {
        margin-right: 11px;
        cursor: pointer;
    }
    @include respond($phone) {
        margin-top: 80px;
    }
    .input {
        &.error {
            .input__input {
                //border: 1px solid red;
                &:focus {
                    border: none;
                }
            }
            .input__error {
                visibility: unset;
                opacity: 1;
                bottom: 25px;
            }
        }
    }
}